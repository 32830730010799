import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { API, ConversationConfigUpdateResponse } from '@kleo/types'
import mime from 'mime'

import { useEventLogger } from 'hooks/useEventLogger'

import { EmptyError, UploadError, UploadSchemaError } from 'utils/appError'
import { formatMaxFileSize } from 'utils/formatter'
import { sanitize } from 'utils/htmlSanitize'
import { fetchData, putFileIntoStorage } from 'utils/http/methods'
import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import type { FEUIConfig, UploadDocContents } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

type UploadContextType = {
  deleteDocumentContent: (botName: string, conversationIDsToDelete: string[]) => void
  docContents: Record<string, Record<string, UploadDocContents>>
  isFileUploading: boolean
  isWarningAcknowledged: boolean
  maxFileNameLength: number
  maxFileSize: string
  maxSizeOCR: { mb: string; value: number }
  replaceConversationIdInDocContents: (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => void
  setDocContents: Dispatch<SetStateAction<Record<string, Record<string, UploadDocContents>>>>
  setIsWarningAcknowledged: Dispatch<SetStateAction<boolean>>
  setUploadError: Dispatch<SetStateAction<string>>
  uploadError: string
  uploadFileMaxCount: number
  uploadFileRequest: (
    botName: string,
    file: File,
    saveConversations: boolean,
    uploadTokenMaxSize: number,
    kBotUploadedCharacters: number,
    conversationID?: string,
    append?: boolean
  ) => void
  uploadModalOperations: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}

export const UploadContext = createContext<UploadContextType>({} as UploadContextType)

type UploadProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const UploadProvider = ({ children, config }: UploadProviderProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { logUIErrorEvent } = useEventLogger()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t, language } = useI18Context()

  const [isFileUploading, setIsFileUploading] = useState(false)
  const [docContents, setDocContents] = useState<Record<string, Record<string, UploadDocContents>>>({})

  const [uploadError, setUploadError] = useState('')
  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const { maxFileNameLength, ocrMaxFileLimit, uploadFileMaxCount, uploadFileMaxSize } = useMemo(() => {
    const uploadBotConfig = config?.find((botConfig) => botConfig.botName.toLowerCase() === 'general')

    if (!uploadBotConfig) {
      return {
        maxFileNameLength: 0,
        ocrMaxFileLimit: 0,
        uploadFileMaxCount: 0,
        uploadFileMaxSize: 0,
      }
    }

    return {
      maxFileNameLength: uploadBotConfig.fileNameLength ?? 0,
      ocrMaxFileLimit: uploadBotConfig.ocrMaxFileLimit ?? 0,
      uploadFileMaxCount: uploadBotConfig.uploadFileMaxCount ?? 0,
      uploadFileMaxSize: uploadBotConfig.uploadFileMaxSize ?? 0,
    }
  }, [config])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t)
  }, [uploadFileMaxSize, numberFormat, t])

  const maxSizeOCR = useMemo(() => {
    return { mb: formatMaxFileSize(ocrMaxFileLimit, numberFormat, t), value: ocrMaxFileLimit }
  }, [ocrMaxFileLimit, numberFormat, t])

  const getUploadUrl = async ({ file, rId }: { file: File; rId: string }, botName: string) => {
    try {
      // const string: unknown = 'hello'
      const token = await getToken()

      const response = await fetchData<API.UploadAPI.GetUrlUploadRes>({
        url: `${API_ENDPOINT}/uploadapi/urlUpload`,
        token,
        payload: { contentType: file.type, fileSize: file.size, fileName: file.name, botName: botName },
        rId,
      })

      // if uploadurl is a success, because documentId only exist in success response
      if ('documentId' in response) {
        return { documentId: response.documentId, url: response.url }
      }
      // schema error
      throw new UploadSchemaError(response.errorList?.[0].code)
    } catch (e) {
      if (e instanceof UploadSchemaError) {
        throw new UploadSchemaError(e.message)
      }

      throw new UploadError('upload-request-error-uploadUrl', e as Error)
    }
  }

  const putFileIntoStorageContainer = async ({ url, file, rId }: { url: string; file: File; rId: string }) => {
    try {
      const { status, statusText } = await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
        },
        rId,
      })

      return { status, statusText }
    } catch (e) {
      throw new UploadError('upload-request-error-documentUpload', e as Error)
    }
  }

  const getDocumentText = async ({
    botName,
    documentId,
    rId,
    fileExtension,
  }: {
    botName: string
    documentId: string
    fileExtension: string
    rId: string
  }) => {
    try {
      const token = await getToken()
      const {
        documentId: returnedDocumentId,
        text,
        characterCount,
        usedOCR,
      } = await fetchData<{
        title: string
        documentId: string
        text: string
        characterCount: number
        tokens: number
        usedOCR: boolean
      }>({
        url: `${API_ENDPOINT}/uploadapi/readDocument`,
        token,
        payload: {
          documentId,
          fileExtension,
          botName,
        },
        retries: 0,
        rId,
      })

      return {
        characterCount,
        returnedDocumentId,
        text,
        usedOCR,
      }
    } catch (e) {
      throw new UploadError('upload-request-error-readDocument', e as Error)
    }
  }

  const uploadFileRequest = async (
    botName: string,
    file: File,
    saveConversations: boolean,
    uploadTokenMaxSize: number,
    kBotUploadedCharacters: number,
    conversationID?: string,
    append = false
  ) => {
    // Initialize a global request ID that will be overwritten for each step in the Upload process. The step that fails will be the request ID used in the logEvent
    let rId = ''
    const conversationIDToUse = conversationID ? conversationID : 'temp'
    const botNameForAPIToUse = botName === 'KBOTS' ? 'GENERAL' : botName

    try {
      setIsFileUploading(true)

      // request ID for getUploadUrl
      rId = `${Math.random()}-${new Date().getTime()}`
      const { documentId, url } = await getUploadUrl({ file, rId }, botNameForAPIToUse)

      if (documentId && url) {
        // request ID for putFileIntoStorageContainer
        rId = `${Math.random()}-${new Date().getTime()}`
        const { status, statusText } = await putFileIntoStorageContainer({ url, file, rId })

        if (status === 201 && statusText === 'Created') {
          // request ID for getDocumentText
          rId = `${Math.random()}-${new Date().getTime()}`

          const extensionComponents = file.name.split('.')
          if (extensionComponents.length === 0) {
            throw new UploadError('upload-request-error-no-file-extension')
          }
          const fileExtension = `.${extensionComponents.pop()}`.toLowerCase()
          const { text, characterCount, usedOCR } = await getDocumentText({
            botName: botNameForAPIToUse,
            documentId,
            fileExtension,
            rId,
          })

          // Make sure that we sit below the max character count for a newly uploaded file, taking into consideration K-Bot fileContent and previously uploaded files when applicable
          const remainingCharacters = uploadTokenMaxSize * 4 - kBotUploadedCharacters
          const usedCharacters = docContents?.[botName]?.[conversationIDToUse]?.characterCount || 0
          const exceedsCharacterLimit = characterCount > remainingCharacters - usedCharacters

          if (characterCount === 0) {
            throw new EmptyError()
          } else if (exceedsCharacterLimit) {
            throw new UploadError('upload-request-error-readDocument')
          }
          // if document id sent is the same as the one in the response, we can continue on

          const docContentsToAppend = append ? docContents?.[botName]?.[conversationIDToUse] : undefined

          const fileType = mime.getExtension(file.type) ?? file.type

          // If we are going to show as HTML, we need to sanitize the text here so that if appending, we don't throw away any text that comes after/within dirty HTML.
          const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(fileType.toLocaleLowerCase())

          setDocContents((currentDocContents) => {
            return {
              ...currentDocContents,
              [botName]: {
                ...currentDocContents[botName],
                [conversationIDToUse]: {
                  text: `${docContentsToAppend ? `${docContentsToAppend.text}\n\n` : ''}<b>Source</b>: ${file.name}\n\n${sanitize(text)}`,
                  title: docContentsToAppend ? t('uploadModal.multipleFilesUploaded') : file.name,
                  type: fileType,
                  documentId,
                  characterCount: docContentsToAppend
                    ? currentDocContents[botName][conversationIDToUse].characterCount + characterCount
                    : characterCount,
                  usedOCR: usedOCR && !isImage,
                  size: file.size,
                },
              },
            }
          })
          if (saveConversations && conversationID && conversationID !== 'temp') {
            try {
              const authToken = await getToken()
              const response = await fetchData<ConversationConfigUpdateResponse>({
                url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
                token: authToken,
                setRetrying: () => false,
                payload: {
                  botName,
                  convID: conversationID,
                  config: {
                    fileContext: `${docContentsToAppend ? `${docContentsToAppend.text}\n\n` : ''}<b>Source</b>: ${file.name}\n\n${sanitize(text)}`,
                  },
                  language: 'EN',
                },
                rId: `${Math.random()}-${new Date().getTime()}`,
              })

              if ('errorList' in response) {
                console.error('Error occurred:', response.errorList[0].code)
              }
            } catch (error) {
              console.error('Error occurred when trying to update file context', error)
            }
          }
        } else {
          logUIErrorEvent({
            bot: botName,
            errorMessage: `Did NOT get back status === 201 && statusText === 'Created' when calling putFileIntoStorageContainer. status: ${status}, statusText: ${statusText}`,
            requestId: rId,
          })
        }
      }

      onClose()
    } catch (e) {
      logUIErrorEvent({
        bot: botName,
        error: e as Error,
        errorMessage: 'upload-file-request-error',
        requestId: rId,
      })

      if (e instanceof UploadSchemaError) {
        setUploadError(e.message)
      } else if (e instanceof UploadError) {
        if (e.message === 'upload-request-error-uploadUrl') {
          setUploadError('uploadUrlEndpointFailed')
        } else if (e.message === 'upload-request-error-documentUpload') {
          setUploadError('uploadError')
        } else if (e.message === 'upload-request-error-readDocument') {
          setUploadError('readDocumentEndpointFailed')
        } else {
          setUploadError('uploadError')
        }
      } else if (e instanceof EmptyError) {
        setUploadError('emptyError')
      } else {
        // generic error
        setUploadError('uploadError')
      }
    } finally {
      setIsFileUploading(false)
    }
  }

  /**
   * Deletes an specific file's content
   * @param {string} conversationID - The ID of the conversation to be deleted.
   * @returns {void}
   */
  const deleteDocumentContent = useCallback((botName: string, conversationIDsToDelete: string[]): void => {
    setDocContents((currentDocContents) => {
      const filteredDocContents: Record<string, Record<string, UploadDocContents>> = {}

      if (botName in currentDocContents) {
        // Initialize filteredDocContents[botName] as an empty object if it doesn't exist
        filteredDocContents[botName] = {}

        for (const key in currentDocContents[botName]) {
          if (!conversationIDsToDelete.includes(key)) {
            // Now safely assign the value after initializing the botName object
            filteredDocContents[botName][key] = currentDocContents[botName][key]
          }
        }
      }

      return { ...currentDocContents, ...filteredDocContents }
    })
  }, [])

  const replaceConversationIdInDocContents = (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => {
    const { botName, oldConversationId, newConversationId } = payload
    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setDocContents })
  }

  return (
    <>
      <UploadContext.Provider
        value={{
          deleteDocumentContent,
          docContents,
          isFileUploading,
          isWarningAcknowledged,
          maxFileNameLength,
          maxFileSize,
          maxSizeOCR,
          replaceConversationIdInDocContents,
          setDocContents,
          setIsWarningAcknowledged,
          setUploadError,
          uploadError,
          uploadFileMaxCount,
          uploadFileRequest,
          uploadModalOperations: {
            isOpen,
            onOpen,
            onClose,
          },
        }}
      >
        {children}
      </UploadContext.Provider>
    </>
  )
}

export const useUploadContext = (): UploadContextType => useContext(UploadContext)
