export * as UploadAPI from './api.upload.types.js'
export * as AssessmentAPI from './api.assessment.types.js'
export * as TranslateAPI from './api.translate.types.js'
export * as Error from './api.error.types.js'

export interface StreamMessage {
  type: string
  id: string
  created: number
}
export interface ContentStreamMessage extends StreamMessage {
  type: 'message'
  delta: string
}
export interface DelimiterStreamMessage extends StreamMessage {
  type: 'delimiter'
  delta: string
}

export interface ErrorStreamMessage extends StreamMessage {
  type: 'error'
  delta: string
  code: string
  errType: string
}
export type DocContext = {
  context: string
  docList: string[]
  docCount: number
  tokens: number
  size: number
  gptTotalTokens: number
  gptPromptTokens: number
  gptResponseTokens: number
}

export type DocListDocument = {
  title: string
  category?: string
  url?: string
}

export type Document = { category: string; docList?: string[]; include: boolean }

export interface ContextStreamMessage extends StreamMessage {
  type: 'context'
  context: DocContext
}

export interface TRequest {
  mspId?: string
  mspPrincipalId?: string
  language: 'EN'
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TResponse {}

export interface UIEvent {
  type: string
  api?: string
  route: string
  botName: string
  buildVersion: string
}
export interface ErrorUIEvent extends UIEvent {
  type: 'error'
  duration: number
  error: any
  errorMessage: string
  originalRequestId?: string
}

export type ApiErrorPayload = {
  api?: string
  requestId?: string
  errorMessage: string
  error?: string
  headers?: string[]
  duration?: number
}

export type LogInfoPayload = {
  api?: string
  requestId?: string
  message: string
  headers?: string[]
  duration?: number
}

export type FeedbackPayload = {
  documents?: string[]
  feedback: ChatMessageFeedback
  client?: string
}

export type FeedbackGeneralUSAPayload = {
  data: {
    agreeUseUSA: GeneralUSAFeedback
  }
}

export type ChatMessageFeedback = 'good' | 'bad'
export type GeneralUSAFeedback = 'yes' | 'no'

export type ChatMessageType = {
  content: string
  role: 'assistant' | 'user'
}

export type LogUIEventAPIRequest = TRequest & {
  bot?: string
  buildVersion: string | null
  data?: unknown
  route: string
} & (ApiErrorPayload | LogInfoPayload | FeedbackPayload | FeedbackGeneralUSAPayload)

export type ErrorRes = {
  errorList: ErrorStreamMessage[]
}

export type FEConfig = {
  bgImageDark: string
  bgImageLight: string
  botName: string
  chatSummaryMemory?: number
  fileNameLength?: number
  filters?: FEFilters[]
  folderNameLength?: number
  hasCustomCSS?: boolean
  icon?: string
  index: number
  isHidden: boolean
  lastUpdated?: string
  maxInput: number
  maxTier3QuestionsCount?: number
  navbarLogoDark?: string
  navbarLogoLight?: string
  ocrMaxFileLimit: number
  saveConversations: boolean
  route: string
  supportedFileExtensions?: Array<string>
  supportedMimeTypes?: Array<string>
  type: string
  uploadFileMaxSize?: number
  uploadFileMaxCount?: number
  uploadTokenMaxSize?: number
  urlLinks?: {
    baseURL?: string
    fallbackURL: string
  }
  hideRightDrawer?: boolean
}

export type BEConfig = {
  'PROMPTS.CONTEXT.EN'?: string
  'PROMPTS.CS_INDEX.EMBEDDINGS_NEIGHBOR_COUNT': string
  'PROMPTS.CS_INDEX.USE_EMBEDDINGS': string
  'PROMPTS.DOC_TEMPLATE.EN'?: string
  'PROMPTS.LENGTH.EN'?: string
  'PROMPTS.SYSTEM.EN': string
  'PROMPTS.TEMPERATURE'?: string
  BOT_AI_MODEL: string
  CHAT_SUMMARY_MEMORY?: number
  FILENAME_LENGTH?: number
  FILENAME_VALIDATION?: string
  FOLDERNAME_LENGTH?: number
  OCR_MAX_FILE_LIMIT?: number
  SAVE_CONVERSATIONS?: boolean
  SECURITY_GROUPS?: string[]
  SHOULD_GENERATE_CHAT_SUMMARY?: boolean
  SUPPORTED_FILE_EXTENSIONS?: Array<string>
  SUPPORTED_MIME_TYPES?: Array<string>
  UPLOAD_FILE_MAX_SIZE?: number
  UPLOAD_FILE_MAX_COUNT?: number
  UPLOAD_TOKEN_MAX_SIZE?: number
  MAX_TIER3_QUESTIONS_COUNT?: number
}

export type FEFilters = {
  key: string
  // label tells us what label name to use for the Filter. Since filters are dependent on bot-specific config, we can't add these to our main translation file
  label: {
    en: string
    fr: string
  }
  // isHidden tells us whether to display the filter in the UI
  isHidden?: boolean
  // type tell us what type of component to display for the filter
  type: 'single' | 'multi' | 'checkbox'
  // disableToggle tells us whether or not to display a checkbox for type: "single" or "multi"
  disableToggle?: boolean
  // if we allow the filter to be toggled, defaultChecked determines if it should start out checked
  defaultChecked?: boolean
}

export type FormatErrorResultsType = {
  message: string
  stack: string | undefined
  cause: any
  [key: string]: any // Add an index signature for dynamic properties
}

export enum InfoSource {
  GENERAL = 'GENERAL',
  FILE_CONTEXT = 'FILE_CONTEXT',
  BOTH = 'BOTH',
}

export interface DocChatDocumentAPIResponse extends TResponse, Array<DocListDocument> {}

export type DocList = {
  documents: DocListDocument[]
}
export type OpenAITemperature = '0.0' | '0.5' | '1.0'
