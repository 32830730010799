import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { API } from '@kleo/types'

import { useAuthContext } from '../providers/AuthProvider'
import { useConfigContext } from '../providers/ConfigurationProvider'
import {
  UIErrorEventPayload,
  UIFeedbackGeneralUSAPayload,
  UIFeedbackPayload,
  UILogInfoEventPayload,
} from '../types/types'
import { fetchData } from '../utils/http/methods'

export const useEventLogger = () => {
  const location = useLocation()
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()

  const logEvent = useCallback(
    async (payload: API.ApiErrorPayload | UIFeedbackPayload | UILogInfoEventPayload | UIFeedbackGeneralUSAPayload) => {
      try {
        const authToken = await getToken()
        // This querySelector gets our build version for the app so we can relate it to GitHub deployments
        const metaTag = document.querySelector('meta[build-version]')

        await fetchData({
          url: `${API_ENDPOINT}/chatapi/logUIEvent`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            ...payload,
            route: location.pathname,
            buildVersion: metaTag && metaTag.getAttribute('build-version'),
          },
          rId: `${Math.random()}-${new Date().getTime()}`,
        })
      } catch (e) {
        console.error('Error: Failed to send information to logUIEvent', e)
      }
    },
    [API_ENDPOINT, getToken, location.pathname]
  )

  const logUIErrorEvent = useCallback(
    async (payload: UIErrorEventPayload) => {
      const { api, bot, data, duration, error, errorMessage, headers, requestId } = payload
      logEvent({
        bot,
        errorMessage,
        error: JSON.stringify(formatError(error?.cause, error)),
        api,
        requestId,
        headers,
        duration,
        data,
      })
    },
    [logEvent]
  )
  const logUIInfoEvent = useCallback(
    async (payload: UILogInfoEventPayload) => {
      const { api, bot, data, duration, headers, message, requestId } = payload
      logEvent({
        bot,
        message,
        api,
        requestId,
        headers,
        duration,
        data,
      })
    },
    [logEvent]
  )

  const logUIFeedbackEvent = useCallback(
    async (payload: UIFeedbackPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  const logUIGeneralUSAEvent = useCallback(
    async (payload: UIFeedbackGeneralUSAPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  return { logUIErrorEvent, logUIInfoEvent, logUIFeedbackEvent, logUIGeneralUSAEvent }
}

export const formatError = <E, V>(_: E, value: V): E | V | API.FormatErrorResultsType => {
  if (value instanceof Error) {
    const err: Error = value
    const props = Object.keys(err).filter((i) => i !== 'message' && i !== 'stack')
    const results: API.FormatErrorResultsType = {
      message: err.message,
      stack: err.stack,
      cause: formatError(_, err.cause as Error),
    }
    props.forEach((i) => {
      results[i as keyof API.FormatErrorResultsType] = (err as never)[i]
    })
    return results
  } else {
    return value
  }
}
