import { useCallback, useEffect, useState } from 'react'

import { fetchData } from 'utils/http/methods'

import { useAuthContext } from 'providers/AuthProvider'
import { useConfigContext } from 'providers/ConfigurationProvider'

import { Request } from 'types/types'

import { useLRUCache } from './useLRUCache'

type UseFetcherProps<T> = {
  allowRefetch?: boolean
  url: string
  noCache?: boolean
  shouldFetch?: boolean
  payload?: Request
  onError?: (e: unknown) => void
  onSuccess?: (data: T) => void
  final?: (data?: T) => void
}

export const useFetcher = <T>({
  allowRefetch = false,
  url,
  noCache = false,
  shouldFetch,
  payload,
  onError,
  onSuccess,
  final,
}: UseFetcherProps<T>) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const [getCachedValue, setCachedValue] = useLRUCache<T, object>()

  const [isCallingEndpoint, setIsCallingEndpoint] = useState(false)
  const [data, setData] = useState<T | null>(null)

  const callEndpoint = useCallback(async () => {
    setIsCallingEndpoint(true)

    const rId = `${Math.random()}-${new Date().getTime()}`
    const key = payload ? [{ url }, payload] : [{ url }]

    try {
      const cacheData = await getCachedValue(key)

      // if caching is enabled and data is in cache, use it
      if (!noCache && cacheData) {
        setData(cacheData)
      } else {
        const authToken = await getToken()
        const responseData = await fetchData<T>({
          url: `${API_ENDPOINT}${url}`,
          token: authToken,
          rId,
          ...(payload && { payload }),
        })

        if (responseData) {
          setCachedValue(key, responseData)
        }
        setData(responseData)
        onSuccess && onSuccess(responseData)
      }
    } catch (e) {
      onError && onError(e)
      setData(null)
    } finally {
      final && final()
      setIsCallingEndpoint(false)
    }
  }, [payload, url, getCachedValue, noCache, getToken, API_ENDPOINT, onSuccess, setCachedValue, onError, final])

  useEffect(() => {
    if ((!data || allowRefetch) && !isCallingEndpoint && (shouldFetch === undefined ? true : shouldFetch)) {
      callEndpoint()
    }
  }, [shouldFetch, callEndpoint, data, isCallingEndpoint, allowRefetch])

  return {
    data,
    isLoading: isCallingEndpoint,
  }
}
