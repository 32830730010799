import { useEffect, useMemo } from 'react'
import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { Box } from '@chakra-ui/layout'

import { CreateEdit } from 'components/kbots/CreateEdit'
import { KBotsTabPanels, KBotsTabs } from 'components/kbots/KBotsTabs'
import { ViewKBots } from 'components/kbots/ViewKBots'

import { formatMaxFileSize } from 'utils/formatter'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { KBotBEConfig, TabsType } from 'types/types'

export const KBots = ({ config }: { config: KBotBEConfig }) => {
  const {
    UPLOAD_TOKEN_MAX_SIZE: uploadTokenMaxSize,
    FILENAME_LENGTH: fileNameLength,
    UPLOAD_FILE_MAX_COUNT: uploadFileMaxCount,
  } = config.be

  const { setShouldFetchKBots } = useKBotContext()
  const { isLightMode } = useSettingsContext()
  const { language, t } = useI18Context()

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const jsonMaxFileSize = useMemo(() => {
    return formatMaxFileSize(1500000, numberFormat, t)
  }, [numberFormat, t])

  const tabSections: TabsType[] = useMemo(
    () => [
      {
        label: t('kBots.view'),
        component: <ViewKBots />,
        route: '/viewKBots',
      },
      {
        label: t('kBots.createEdit.create'),
        component: (
          <CreateEdit
            botName={'KBOTS'}
            jsonMaxFileSize={jsonMaxFileSize}
            maxFileNameLength={fileNameLength ?? 0}
            state={'create'}
            uploadFileMaxCount={uploadFileMaxCount ?? 0}
            uploadTokenMaxSize={uploadTokenMaxSize ?? 0}
          />
        ),
        route: '/createKBot',
      },
      {
        label: t('kBots.createEdit.edit'),
        component: (
          <CreateEdit
            botName={'KBOTS'}
            jsonMaxFileSize={jsonMaxFileSize}
            maxFileNameLength={fileNameLength ?? 0}
            state={'edit'}
            uploadFileMaxCount={uploadFileMaxCount ?? 0}
            uploadTokenMaxSize={uploadTokenMaxSize ?? 0}
          />
        ),
        route: '/editKBot',
      },
    ],
    [fileNameLength, jsonMaxFileSize, t, uploadFileMaxCount, uploadTokenMaxSize]
  )

  useEffect(() => {
    setShouldFetchKBots(true)
  }, [setShouldFetchKBots])

  return (
    <Box
      backgroundImage={`${isLightMode ? '../images/fluid.webp' : '../images/geometry.webp'}`}
      className="flex flex-col h-full overflow-x-hidden bg-cover"
    >
      <RouterRoutes>
        <Route
          path="/*"
          element={
            <Box className="relative flex flex-col flex-grow my-2 overflow-hidden md:my-4 width-layout-xl bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-xl h-full">
              <Box className="flex flex-col h-full p-2">
                <KBotsTabs tabSections={tabSections} />
                <KBotsTabPanels tabSections={tabSections} />
              </Box>
            </Box>
          }
        />
      </RouterRoutes>
    </Box>
  )
}
