import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import { useEventLogger } from '../hooks/useEventLogger'
import {
  APIDataResponse,
  BarChartData,
  ChartRequest,
  ChartType,
  ChatMessage,
  ChildrenProps,
  LineChartData,
  PieChartData,
  TableData,
} from '../types/types'
import { NetworkTimeoutError } from '../utils/appError'
import { chartDataExtractor } from '../utils/chart'
import { fetchData } from '../utils/http/methods'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useMessagesContext } from './MessageProvider'

type ChartContextType = {
  chartRequest: ({
    message,
    chartType,
    botName,
    messageIndex,
  }: {
    message: ChatMessage
    chartType: ChartType
    botName: string
    messageIndex: number
  }) => Promise<ChatMessage | 'generate' | 'api' | 'timeout'>
  clearBotAllChartData: (bot: string) => void
  getLineChartData: (bot: string, index: number, conversationID?: string) => LineChartData | undefined
  getBarChartData: (bot: string, index: number, conversationID?: string) => BarChartData | undefined
  getPieChartData: (bot: string, index: number, conversationID?: string) => PieChartData | undefined
  getTableData: (bot: string, index: number, conversationID?: string) => TableData | undefined
  replaceConversationIdInCharts: (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => void
}

// This type translates to: Record<{name of the bot}, Record<{ID of the conversation}, Record<{index of the message}, {chart/table data and information}>>>
type ChartStateType<T> = Record<string, Record<string, Record<number, T>>>

export const ChartContext = createContext<ChartContextType>({} as ChartContextType)

export const ChartProvider = ({ children }: ChildrenProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { getCurrentConversationIDForBot } = useMessagesContext()

  const { logUIErrorEvent } = useEventLogger()

  const [lineCharts, setLineCharts] = useState<ChartStateType<LineChartData>>({})

  const [barCharts, setBarCharts] = useState<ChartStateType<BarChartData>>({})

  const [pieCharts, setPieCharts] = useState<ChartStateType<PieChartData>>({})

  const [tables, setTables] = useState<ChartStateType<TableData>>({})

  const chartRequest = async ({
    message,
    chartType,
    botName,
    messageIndex,
  }: {
    message: ChatMessage
    chartType: ChartType
    botName: string
    messageIndex: number
  }) => {
    const startTimeFetch = new Date().getTime()
    const rId = `${Math.random()}-${new Date().getTime()}`

    try {
      const authToken = await getToken()
      const payload: ChartRequest = {
        botName,
        prompt: message,
        language: 'EN',
        type: chartType,
      }
      const response = await fetchData<APIDataResponse>({
        url: `${API_ENDPOINT}/chatapi/chart`,
        token: authToken,
        payload,
        rId,
      })

      const responseOutput = handleResponse(response.messages, chartType, botName, messageIndex, rId)
      if (responseOutput) {
        return responseOutput
      } else {
        // Return a string 'generate' so that we can interpret the type of error we are experiencing when trying to generate chart data
        return 'generate'
      }
    } catch (e) {
      const endTimeFetch = new Date().getTime()
      logUIErrorEvent({
        api: '/chatapi/chart',
        bot: botName,
        duration: endTimeFetch - startTimeFetch,
        error: e as Error,
        errorMessage: 'chart-request-fetch-error',
        requestId: rId,
      })

      if (e instanceof NetworkTimeoutError) {
        return 'timeout'
      }
      // Return a string 'api' so that we can interpret the type of error we are experiencing when trying to fetch chart data
      return 'api'
    }
  }

  const handleResponse = (
    message: ChatMessage[],
    chartType: ChartType,
    botName: string,
    messageIndex: number,
    rId: string
  ): ChatMessage | false => {
    const startTimeFetch = new Date().getTime()
    try {
      const [{ content }] = message
      if (chartType === 'LINE') {
        const lineChartData = chartDataExtractor<LineChartData>(content)
        if (lineChartData) {
          updateChartData(setLineCharts, lineChartData, messageIndex, botName)
        } else {
          // Return false, indicating that we failed to extract chart data
          return false
        }
      } else if (chartType === 'BAR') {
        const barChartData = chartDataExtractor<BarChartData>(content)
        if (barChartData) {
          updateChartData(setBarCharts, barChartData, messageIndex, botName)
        } else {
          // Return false, indicating that we failed to extract chart data
          return false
        }
      } else if (chartType === 'PIE') {
        const pieChartData = chartDataExtractor<PieChartData>(content)
        if (pieChartData) {
          updateChartData(setPieCharts, pieChartData, messageIndex, botName)
        } else {
          // Return false, indicating that we failed to extract chart data
          return false
        }
      } else if (chartType === 'TABLE') {
        const tableData = chartDataExtractor<TableData>(content)
        if (tableData) {
          updateChartData(setTables, tableData, messageIndex, botName)
        } else {
          // Return false, indicating that we failed to extract chart data
          return false
        }
      }

      return { content: content, role: 'chart' }
    } catch (e) {
      const endTimeFetch = new Date().getTime()
      logUIErrorEvent({
        api: '/chatapi/docChat',
        bot: botName,
        duration: endTimeFetch - startTimeFetch,
        error: e as Error,
        errorMessage: 'chart-data-extraction-error',
        requestId: rId,
      })
      return false
    }
  }

  const getLineChartData = (bot: string, index: number, conversationID?: string) => {
    if (conversationID && lineCharts[bot]?.[conversationID]) {
      return lineCharts[bot][conversationID][index]
    }
    return
  }
  const getBarChartData = (bot: string, index: number, conversationID?: string) => {
    if (conversationID && barCharts[bot]?.[conversationID]) {
      return barCharts[bot][conversationID][index]
    }
    return
  }
  const getPieChartData = (bot: string, index: number, conversationID?: string) => {
    if (conversationID && pieCharts[bot]?.[conversationID]) {
      return pieCharts[bot][conversationID][index]
    }
    return
  }
  const getTableData = (bot: string, index: number, conversationID?: string) => {
    if (conversationID && tables[bot]?.[conversationID]) {
      return tables[bot][conversationID][index]
    }
    return
  }

  const clearBotAllChartData = (bot: string) => {
    const conversationID = getCurrentConversationIDForBot(bot)
    if (conversationID) {
      setLineCharts((lineCharts) => ({
        ...lineCharts,
        [bot]: { ...lineCharts[bot], [conversationID]: {} },
      }))
      setBarCharts((barCharts) => ({
        ...barCharts,
        [bot]: { ...barCharts[bot], [conversationID]: {} },
      }))
      setPieCharts((pieCharts) => ({
        ...pieCharts,
        [bot]: { ...pieCharts[bot], [conversationID]: {} },
      }))
      setTables((tables) => ({
        ...tables,
        [bot]: { ...tables[bot], [conversationID]: {} },
      }))
    }
  }

  /**
   * Updates the chart data for a specific message in a conversation.
   * @param {Dispatch<SetStateAction<ChartStateType<T>>>} setChartData - The setter function to update the chart data state.
   * @param {T} newData - The new data to be added to the chart.
   * @param {number} messageIndex - The index of the message associated with the new data.
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {void}
   */
  const updateChartData = <T extends object>(
    setChartData: Dispatch<SetStateAction<ChartStateType<T>>>,
    newData: T,
    messageIndex: number,
    botName: string
  ): void => {
    const conversationID = getCurrentConversationIDForBot(botName)
    if (conversationID) {
      setChartData((currentChartData: ChartStateType<T>) => {
        const updatedChartData = {
          ...currentChartData,
          [botName]: {
            ...(currentChartData[botName] || {}),
            [conversationID]: {
              ...(currentChartData[botName]?.[conversationID] || {}),
              [messageIndex]: newData,
            },
          },
        }
        return updatedChartData
      })
    }
  }

  // Generalized function to replace conversationId for different chart types
  const replaceConversationIdInCharts = (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => {
    const { botName, oldConversationId, newConversationId } = payload

    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setLineCharts })
    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setBarCharts })
    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setPieCharts })
    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setTables })
  }

  return (
    <>
      <ChartContext.Provider
        value={{
          chartRequest,
          getLineChartData,
          getBarChartData,
          getPieChartData,
          getTableData,
          clearBotAllChartData,
          replaceConversationIdInCharts,
        }}
      >
        {children}
      </ChartContext.Provider>
    </>
  )
}

export const useChartContext = (): ChartContextType => useContext(ChartContext)
