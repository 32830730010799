import { useCallback, useEffect, useMemo, useState } from 'react'
import * as BiIcons from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, List, ListItem, Text } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { KBotBaseConfig, KBotConfig } from '@kleo/types'

import { IconButton } from 'components/buttons/IconButton'
import { TextButton } from 'components/buttons/TextButton'
import { TextIconButton } from 'components/buttons/TextIconButton'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { HTMLStringParser } from 'components/HTMLStringParser'
import { ModalBox } from 'components/Modal'
import { PromptLibrary } from 'components/PromptLibrary'

import { formattedDate } from 'utils/formattedDate'
import { isLanguagePromptsNull } from 'utils/KBotsUtils'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

type ViewKBotModalProps = {
  isOpen: boolean
  onClose: () => void
  kBotToFetch: KBotBaseConfig
}

export const ViewKBotModal = ({ kBotToFetch, isOpen, onClose }: ViewKBotModalProps) => {
  const { date, description, name, source, template, templateId } = kBotToFetch
  const headerClass = 'font-bold text-sm md:text-base'

  const navigate = useNavigate()
  const { language, t, languageAbbreviation } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const {
    deleteKBot,
    downloadKBot,
    fetchIndividualKBotDataError,
    getIndividualKBotData,
    isDeletingKBot,
    isDownloadingKBot,
    isFetchingIndividualKBotData,
    setEditSelectedBot,
    setFetchingIndividualKBotDataError,
  } = useKBotContext()
  const [kBot, setKBot] = useState<KBotConfig | null>(null)

  const { isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel } = useDisclosure()

  const handleEditClick = useCallback(() => {
    setEditSelectedBot(kBot)
    navigate('/k-bots/editKBot')
  }, [kBot, navigate, setEditSelectedBot])

  const handleClose = useCallback(() => {
    onClose()
    setFetchingIndividualKBotDataError(null)
  }, [onClose, setFetchingIndividualKBotDataError])

  const handleDownloadClick = useCallback(() => {
    if (kBot) {
      downloadKBot(kBot)
    }
  }, [downloadKBot, kBot])

  const handleDeleteClick = useCallback(() => {
    if (kBot) {
      deleteKBot(kBot, onCloseDel, handleClose)
    }
  }, [deleteKBot, kBot, handleClose, onCloseDel])

  const dateToDisplay = useMemo(() => {
    return formattedDate(date, language)
  }, [date, language])

  const getKBotDetails = useCallback(async () => {
    const data = await getIndividualKBotData(source, name)
    setKBot(data)
  }, [getIndividualKBotData, name, source])

  useEffect(() => {
    if (isOpen) {
      getKBotDetails()
    }
  }, [getKBotDetails, isOpen])

  return (
    <>
      <ModalBox
        isOpen={isOpenDel}
        modalBody={
          <Box>
            <Text as="h2" className="mb-2 font-bold">
              {t('kBots.areYouSureDeleteThisKBot')}
            </Text>
            <Text as="p">{t('kBots.areYouSureDeleteThisKBotDescription')}</Text>
          </Box>
        }
        modalFooter={
          <Box className="flex flex-wrap gap-3">
            <TextButton
              aria-label="close"
              className="ml-auto text-sm md:text-base"
              id="close-button"
              onClick={onCloseDel}
              isDisabled={isDeletingKBot}
            >
              {t('controls.cancel')}
            </TextButton>
            <TextButton
              aria-label="delete-kbot"
              className="ml-auto text-sm md:text-base"
              id="delete-kbot-button"
              onClick={handleDeleteClick}
              variant={isLightMode ? 'kpmgCobaltBlue' : 'kpmgWhite'}
              isLoading={isDeletingKBot}
            >
              {t('kBots.yesDeleteThisKBot')}
            </TextButton>
          </Box>
        }
        modalHeader={t('kBots.deleteThisKBot')}
        onClose={onCloseDel}
      />
      <ModalBox
        closeOnOverlayClick={true}
        hasOverflowedBody
        isOpen={isOpen}
        modalHeader={template[languageAbbreviation]}
        modalHeaderClass="text-xl md:text-2xl"
        onClose={handleClose}
        showHeaderDivider={true}
        size="4xl"
        customHeaderContent={
          <Box className="flex flex-row flex-wrap justify-end gap-2">
            {source === 'user' && (
              <TooltipButton
                key={`${templateId}-view-k-bot-edit`}
                button={
                  <TextIconButton
                    aria-label="edit-k-bot"
                    leftIcon={<BiIcons.BiEdit />}
                    onClick={handleEditClick}
                    size={isTablet ? 'md' : 'sm'}
                    test-id="edit-kbot-button"
                    variant={isLightMode ? 'kpmgBlue' : 'kpmgCobaltBlue'}
                    isDisabled={!!(fetchIndividualKBotDataError && !!fetchIndividualKBotDataError.length)}
                  >
                    {t('controls.edit')}
                  </TextIconButton>
                }
                label={t('kBots.editBot')}
                isDisabled={!!(fetchIndividualKBotDataError && !!fetchIndividualKBotDataError.length)}
              />
            )}
            <Box className="flex gap-2">
              <TooltipButton
                key={`${templateId}-view-k-bot-download`}
                button={
                  <IconButton
                    aria-label="download-k-bot"
                    iconClassName="text-2xl"
                    iconName={BiIcons.BiArrowToBottom}
                    isLoading={isDownloadingKBot}
                    onClick={handleDownloadClick}
                    size={isTablet ? 'md' : 'sm'}
                    test-id="download-kbot-button"
                    variant={isLightMode ? 'blackAlpha' : 'whiteAlpha'}
                  />
                }
                label={t('kBots.downloadBot')}
              />
              {source === 'user' && (
                <TooltipButton
                  key={`${templateId}-view-k-bot-delete`}
                  button={
                    <IconButton
                      aria-label="delete-k-bot"
                      iconClassName="text-2xl"
                      iconName={BiIcons.BiTrash}
                      isLoading={isDeletingKBot}
                      onClick={onOpenDel}
                      size={isTablet ? 'md' : 'sm'}
                      test-id="delete-kbot-button"
                      variant={isLightMode ? 'blackAlpha' : 'whiteAlpha'}
                    />
                  }
                  label={t('kBots.deleteBot')}
                />
              )}
            </Box>
          </Box>
        }
        subHeaders={
          <Box className="flex flex-col gap-1 mt-4">
            <Text className="text-base font-normal">{dateToDisplay}</Text>
            <List spacing={1}>
              {description
                .filter((desc) => desc[languageAbbreviation] !== null)
                .map((desc) => (
                  <ListItem key={`${desc[languageAbbreviation]}-view-k-bot`} className="text-sm font-normal">
                    {desc[languageAbbreviation] as string}
                  </ListItem>
                ))}
            </List>
          </Box>
        }
        modalBody={
          <Box className="flex flex-col max-h-[50vh]">
            {isFetchingIndividualKBotData ? (
              [...Array(5)].map((e, index) => <Skeleton key={`${e}_${index}`} className="w-full my-2" height="16px" />)
            ) : fetchIndividualKBotDataError && fetchIndividualKBotDataError.length ? (
              <Box className="flex w-full p-2 text-red-400 md:p-3">
                {t(`kBots.error.${fetchIndividualKBotDataError}`)}
              </Box>
            ) : kBot ? (
              <>
                <Box className="mb-3">
                  <Text className={headerClass}>{t('kBots.createEdit.instructions')}</Text>
                  {kBot.userInstructions.length ? (
                    <Text className="text-xs md:text-sm">{kBot.userInstructions}</Text>
                  ) : (
                    <Text className="text-xs italic md:text-sm">
                      {t('kBots.thisKBotHasNo', { kBotItem: t('kBots.createEdit.instructions').toLocaleLowerCase() })}
                    </Text>
                  )}
                </Box>
                <Box className="mb-3">
                  <Text className={headerClass + ' mb-2'}>{t('kBots.createEdit.prompts')}</Text>
                  {kBot.starterPrompts &&
                  kBot.starterPrompts.length &&
                  !isLanguagePromptsNull(languageAbbreviation, kBot.starterPrompts) ? (
                    <PromptLibrary
                      botName="general"
                      promptLibraryArray={kBot.starterPrompts}
                      isKBot={true}
                      kBotIcon={kBot.template.icon}
                      isReadOnly={true}
                    />
                  ) : (
                    <Text className="text-xs italic md:text-sm">
                      {t('kBots.thisKBotHasNo', { kBotItem: t('kBots.createEdit.prompts').toLocaleLowerCase() })}
                    </Text>
                  )}
                </Box>
                <Box className="mb-3">
                  <Text className={headerClass}>{t('kBots.createEdit.knowledgeBase')}</Text>
                  <Box className="overflow-y-auto min-h-6 max-h-[50%]">
                    {kBot.fileContent ? (
                      <HTMLStringParser
                        className="text-xs md:text-sm"
                        htmlString={'<html><body>' + kBot.fileContent + '</body></html>'}
                      />
                    ) : (
                      <Text className="text-xs italic md:text-sm">
                        {t('kBots.thisKBotHasNo', {
                          kBotItem: t('kBots.createEdit.knowledgeBase').toLocaleLowerCase(),
                        })}
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box className="mb-3">
                  <Text className={headerClass}>{t('kBots.createEdit.temperature')}</Text>
                  <Text as="span" className="text-xs md:text-sm">
                    <Text as="span" className="font-bold">
                      {t(`kBots.createEdit.temperatures.${kBot.kbotTemperature}.name`)}
                    </Text>
                    {` - ${t(`kBots.createEdit.temperatures.${kBot.kbotTemperature}.details`)}`}
                  </Text>
                </Box>
              </>
            ) : null}
          </Box>
        }
      />
    </>
  )
}
