import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import {
  BotCombinedValues,
  BotFilterOptions,
  BotFilterValues,
  BotFormOptions,
  BotFormValues,
  FEUIConfig,
  FilterValue,
} from 'types/types'

// "temp" is used as the default string value for a conversation that is yet to be mapped to a proper uuid
const tempDefaultValues = {
  userQuery: '',
  clicked: null,
  folderNameInput: null,
  translationTargetLanguage: null,
}

type FiltersContextType = {
  deleteSelectedFilterValues: (conversationIDsToDelete: string[]) => void
  deleteSelectedFormValues: (conversationIDsToDelete: string[]) => void
  getAvailableFilterOptions: (botName: string) => BotFilterOptions | undefined
  getAvailableFormOptions: (botName: string) => BotFormOptions | undefined
  getDefaultFilterValues: (botName: string) => BotFilterValues | undefined
  getSelectedFormAndFilterValues: (botName: string, conversationID: string) => BotCombinedValues
  getIsFetchingDocumentData: (botName: string) => boolean
  getSelectedBotFilterValues: (botName: string) => Record<string, BotFilterValues>
  getSelectedBotFormValues: (botName: string) => Record<string, BotFormValues>
  getSelectedFilterValues: (botName: string, conversationID: string) => BotFilterValues
  getSelectedFormValues: (botName: string, conversationID: string) => BotFormValues
  initializeFilterOptionsAndValues: (
    botName: string,
    filterOptions: BotFilterOptions,
    currentConversationID: string
  ) => void
  replaceConversationIdInFiltersAndForms: (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => void
  setSelectedFilterValues: Dispatch<SetStateAction<Record<string, Record<string, BotFilterValues>>>>
  uncheckAllFilterValues: (filters: BotFilterValues['filters']) => BotFilterValues['filters']
  updateAvailableFilterOptions: (botName: string, filterOptions: BotFilterOptions) => void
  updateAvailableFormOptions: (botName: string, formOptions: BotFormOptions) => void
  updateIsFetchingDocumentData: (botName: string, isFetching: boolean) => void
  updateSelectedFilterAndFormValues: (
    botName: string,
    conversationID: string,
    newFilterValues?: BotFilterValues,
    newFormValues?: BotFormValues
  ) => void
  updateSelectedFilterValues: (botName: string, conversationID: string, filterValues?: Partial<BotFilterValues>) => void
  updateSelectedFormValues: (botName: string, conversationID: string, formValues: Partial<BotFormValues>) => void
}

export const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType)

type FiltersAndFormsProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const FiltersAndFormsProvider = ({ children, config }: FiltersAndFormsProviderProps) => {
  // Options presented to the user based on selections. Used for remembering state when jumping between pages
  const [availableFilterOptions, setAvailableFilterOptions] = useState<Record<string, BotFilterOptions>>({})
  // Selections made by the user. Used for remembering state when jumping between pages
  const [selectedFilterValues, setSelectedFilterValues] = useState<Record<string, Record<string, BotFilterValues>>>({})
  // Options presented to the user based on selections. Used for remembering state when jumping between pages
  const [availableFormOptions, setAvailableFormOptions] = useState<Record<string, BotFormOptions>>({})
  // Selections made by the user. Used for remembering state when jumping between pages
  const [selectedFormValues, setSelectedFormValues] = useState<Record<string, Record<string, BotFormValues>>>({})
  // These are the values we can use to reset filter values. They are the "empty" or "default" values for each bot's filters
  const [defaultFilterValues, setDefaultFilterValues] = useState<Record<string, BotFilterValues>>({})
  // These are the boolean values that represent whether a bot is currently fetching document data
  const [isFetchingDocumentData, setIsFetchingDocumentData] = useState<Record<string, boolean>>({})

  const defaultValues: {
    defaultFormValues: Record<string, Record<string, BotFormValues>>
    defaultSelectedFilterValues: Record<string, Record<string, BotFilterValues>>
    defaultFilterValues: Record<string, BotFilterValues>
  } = useMemo(() => {
    if (!config) {
      return { defaultFormValues: {}, defaultSelectedFilterValues: {}, defaultFilterValues: {} }
    }

    return config.reduce(
      (acc, botConfig) => ({
        // Initial form values for initial conversations
        defaultFormValues: {
          ...acc.defaultFormValues,
          [botConfig.botName]: {
            // "temp" is used as the default string value for a conversation that is yet to be mapped to a proper uuid
            temp: tempDefaultValues,
          },
        },
        // Default values for filters
        defaultFilterValues: {
          ...acc.defaultFilterValues,
          [botConfig.botName]: {
            filters:
              botConfig.filters?.reduce<Record<string, FilterValue>>((accu, filter) => {
                return {
                  ...accu,
                  [filter.key]: {
                    items: [],
                    isChecked: !!filter.defaultChecked,
                  },
                }
              }, {}) ?? {},
          },
        },
        // Initial selection of filters for initial conversations
        defaultSelectedFilterValues: {
          ...acc.defaultSelectedFilterValues,
          [botConfig.botName]: {
            // "temp" is used as the default string value for a conversation that is yet to be mapped to a proper uuid
            temp: {
              filters:
                botConfig.filters?.reduce<Record<string, FilterValue>>((accu, filter) => {
                  return {
                    ...accu,
                    [filter.key]: {
                      items: [],
                      isChecked: !!filter.defaultChecked,
                    },
                  }
                }, {}) ?? {},
            },
          },
        },
      }),
      { defaultFormValues: {}, defaultSelectedFilterValues: {}, defaultFilterValues: {} }
    )
  }, [config])

  const getSelectedFilterValues = useCallback(
    (botName: string, conversationID: string): BotFilterValues => {
      return selectedFilterValues?.[botName]?.[conversationID]
    },
    [selectedFilterValues]
  )
  const getSelectedFormValues = useCallback(
    (botName: string, conversationID: string) => {
      return selectedFormValues?.[botName]?.[conversationID]
    },
    [selectedFormValues]
  )
  const getSelectedFormAndFilterValues = useCallback(
    (botName: string, conversationID: string): BotFormValues & BotFilterValues => {
      const filterValues = getSelectedFilterValues(botName, conversationID)
      const formValues = getSelectedFormValues(botName, conversationID)
      return { ...filterValues, ...formValues }
    },
    [getSelectedFilterValues, getSelectedFormValues]
  )
  const getSelectedBotFilterValues = useCallback(
    (botName: string) => {
      return selectedFilterValues?.[botName]
    },
    [selectedFilterValues]
  )

  const getSelectedBotFormValues = useCallback(
    (botName: string) => {
      return selectedFormValues?.[botName]
    },
    [selectedFormValues]
  )

  const getAvailableFilterOptions = useCallback(
    (botName: string): BotFilterOptions | undefined => {
      return availableFilterOptions?.[botName]
    },
    [availableFilterOptions]
  )

  const getAvailableFormOptions = useCallback(
    (botName: string): BotFormOptions | undefined => {
      return availableFormOptions?.[botName]
    },
    [availableFormOptions]
  )

  const getDefaultFilterValues = useCallback(
    (botName: string): BotFilterValues | undefined => {
      return defaultFilterValues[botName]
    },
    [defaultFilterValues]
  )

  const getIsFetchingDocumentData = useCallback(
    (botName: string): boolean => {
      return isFetchingDocumentData[botName] ?? false
    },
    [isFetchingDocumentData]
  )

  const updateSelectedFilterValues = useCallback(
    (botName: string, conversationID: string, filterValues?: Partial<BotFilterValues>) => {
      setSelectedFilterValues((currentSelectedFilterValues) => {
        const fallbackValues = filterValues ?? defaultValues.defaultSelectedFilterValues[botName]
        return {
          ...currentSelectedFilterValues,
          [botName]: {
            ...currentSelectedFilterValues[botName],
            [conversationID]: { ...currentSelectedFilterValues[botName]?.[conversationID], ...fallbackValues },
          },
        }
      })
    },
    [defaultValues.defaultSelectedFilterValues]
  )

  const updateSelectedFormValues = useCallback(
    (botName: string, conversationID: string, formValues: Partial<BotFormValues>) => {
      setSelectedFormValues((currentSelectedFormValues) => {
        return {
          ...currentSelectedFormValues,
          [botName]: {
            ...currentSelectedFormValues[botName],
            [conversationID]: { ...currentSelectedFormValues[botName][conversationID], ...formValues },
          },
        }
      })
    },
    []
  )

  const updateAvailableFilterOptions = useCallback((botName: string, filterOptions: BotFilterOptions) => {
    setAvailableFilterOptions((currentAvailableFilterOptions) => {
      return {
        ...currentAvailableFilterOptions,
        [botName]: {
          filters: { ...(currentAvailableFilterOptions[botName]?.filters || {}), ...filterOptions.filters },
        },
      }
    })
  }, [])

  const updateAvailableFormOptions = useCallback((botName: string, formOptions: BotFormOptions) => {
    setAvailableFormOptions((currentAvailableFormOptions) => {
      return {
        ...currentAvailableFormOptions,
        [botName]: { ...currentAvailableFormOptions[botName], ...formOptions },
      }
    })
  }, [])

  const updateIsFetchingDocumentData = useCallback((botName: string, isFetching: boolean) => {
    setIsFetchingDocumentData((currentIsFetchingDocumentData) => {
      return { ...currentIsFetchingDocumentData, [botName]: isFetching }
    })
  }, [])

  // Based on new filter and form values, update those for the specific conversation ID in the function payload
  const updateSelectedFilterAndFormValues = useCallback(
    (
      botName: string,
      conversationID: string,
      newFilterValues?: BotFilterValues,
      newFormValues?: BotFormValues
    ): void => {
      updateSelectedFilterValues(botName, conversationID, newFilterValues || defaultFilterValues[botName])
      updateSelectedFormValues(botName, conversationID, newFormValues || { userQuery: '', clicked: null })
    },
    [defaultFilterValues, updateSelectedFilterValues, updateSelectedFormValues]
  )

  // This function returns all filter values with isChecked set to false
  const uncheckAllFilterValues = useCallback((filters: BotFilterValues['filters']): BotFilterValues['filters'] => {
    const updatedFilters: BotFilterValues['filters'] = {}

    Object.keys(filters).forEach((filterName) => {
      updatedFilters[filterName] = {
        ...filters[filterName],
        isChecked: false,
      }
    })

    return updatedFilters
  }, [])

  // Based on conversationIDs in the function payload, delete those Record's selected filter values
  const deleteSelectedFilterValues = useCallback((conversationIDsToDelete: string[]) => {
    setSelectedFilterValues((currentSelectedFilterValues) => {
      const filteredConversations: Record<string, Record<string, BotFilterValues>> = {}

      for (const key in currentSelectedFilterValues) {
        const conversationRecord = currentSelectedFilterValues[key]
        const filteredRecord: Record<string, BotFilterValues> = {}

        for (const numKey in conversationRecord) {
          if (!conversationIDsToDelete.includes(numKey)) {
            filteredRecord[numKey] = conversationRecord[numKey]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }, [])
  // Based on conversation IDs in the function payload, delete those Record's selected form values
  const deleteSelectedFormValues = useCallback((conversationIDsToDelete: string[]) => {
    setSelectedFormValues((currentSelectedFormValues) => {
      const filteredConversations: Record<string, Record<string, BotFormValues>> = {}
      for (const bot in currentSelectedFormValues) {
        const conversationRecord = currentSelectedFormValues[bot]
        const filteredRecord: Record<string, BotFormValues> = {}

        for (const conversationNumber in conversationRecord) {
          if (!conversationIDsToDelete.includes(conversationNumber)) {
            filteredRecord[conversationNumber] = conversationRecord[conversationNumber]
          }
        }

        // if there are no conversations after what's been deleted, create the default temp object
        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[bot] = filteredRecord
        } else {
          filteredConversations[bot] = {
            temp: tempDefaultValues,
          }
        }
      }

      return filteredConversations
    })
  }, [])

  // This function creates for us the "empty" or "default" values for the bot so that we have a starting point for updating values over time
  const initializeFilterOptionsAndValues = useCallback(
    (botName: string, filterOptions: BotFilterOptions, currentConversationID: string) => {
      const filterKeys = Object.keys(filterOptions.filters)
      if (filterKeys.length === 0) {
        setAvailableFilterOptions((currentAvailableFilterOptions) => {
          return {
            ...currentAvailableFilterOptions,
            [botName]: {
              filters: {},
            },
          }
        })
      } else {
        updateAvailableFilterOptions(botName, filterOptions)
      }

      const filters = filterKeys.reduce<BotFilterValues['filters']>((acc, filter) => {
        acc[filter] = {
          items: [],
          // If there are no filterOptions to select from, default the checkbox selection to false
          isChecked: filterOptions.filters[filter].length > 0,
        }
        return acc
      }, {})

      setDefaultFilterValues((currentDefaultFilterValues) => {
        return { ...currentDefaultFilterValues, [botName]: { filters: filters } }
      })

      setSelectedFilterValues((filterValue) => ({
        ...filterValue,
        [botName]: {
          [currentConversationID]: {
            filters,
          },
        },
      }))
    },
    [updateAvailableFilterOptions]
  )

  const replaceConversationIdInFiltersAndForms = (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => {
    const { botName, oldConversationId, newConversationId } = payload

    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setSelectedFormValues })
    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setSelectedFilterValues })
  }

  // set default values for all bots right off the bat when FE config gets loaded
  useEffect(() => {
    if (defaultValues) {
      setSelectedFormValues(defaultValues.defaultFormValues)
      setSelectedFilterValues(defaultValues.defaultSelectedFilterValues)
      setDefaultFilterValues(defaultValues.defaultFilterValues)
    }
  }, [defaultValues])

  return (
    <>
      <FiltersContext.Provider
        value={{
          deleteSelectedFilterValues,
          deleteSelectedFormValues,
          getAvailableFilterOptions,
          getAvailableFormOptions,
          getDefaultFilterValues,
          getIsFetchingDocumentData,
          getSelectedBotFilterValues,
          getSelectedBotFormValues,
          getSelectedFilterValues,
          getSelectedFormAndFilterValues,
          getSelectedFormValues,
          initializeFilterOptionsAndValues,
          replaceConversationIdInFiltersAndForms,
          setSelectedFilterValues,
          uncheckAllFilterValues,
          updateAvailableFilterOptions,
          updateAvailableFormOptions,
          updateIsFetchingDocumentData,
          updateSelectedFilterAndFormValues,
          updateSelectedFilterValues,
          updateSelectedFormValues,
        }}
      >
        {children}
      </FiltersContext.Provider>
    </>
  )
}

export const useFiltersContext = (): FiltersContextType => useContext(FiltersContext)
