import { ErrorInfo } from 'react'
import { API } from '@kleo/types'

export class APIError extends Error {
  code: number
  requestId: string

  constructor(code: number, requestId: string | null, message?: string, cause?: Error) {
    super(message, { cause })
    this.code = code
    this.requestId = requestId || ''
  }
}

export class GPTError extends Error {
  code: string
  requestId?: string
  constructor(code: string, requestId?: string, message?: string) {
    super(message)
    Object.setPrototypeOf(this, GPTError.prototype)
    this.name = 'GPTError'
    this.code = code
    this.requestId = requestId
  }
}

export class ReactError extends Error {
  componentStack: string

  constructor(message: string, { componentStack }: ErrorInfo, cause?: unknown) {
    super(message, { cause })
    this.name = 'ReactError'
    // componentStack is of type "string | null | undefined". Can't assign a value of "null | undefined" to this.componentStack
    this.componentStack = typeof componentStack === 'string' ? componentStack : ''
  }
}

export class NetworkError extends Error {
  duration = -1
  headers: string[] = []
  status: string

  constructor(message: string, duration: number, headers: string[], status: string, cause?: Error) {
    super(message, { cause })
    this.duration = duration
    this.headers = headers
    this.status = status
    this.name = 'NetworkError'
  }
}

export class UIError extends Error {
  data: { [key: string]: string } = {}
  constructor(message?: string, cause?: Error, data?: { [key: string]: string }) {
    super(message, { cause })
    this.data = data ? data : {}
    this.name = 'UIError'
  }
}

export class NetworkTimeoutError extends UIError {
  // duration = -1
  // headers: string[] = []

  constructor(message?: string, cause?: Error, duration?: number, headers?: string[]) {
    super(
      message,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      { cause },
      {
        duration: duration ?? -1,
        headers: headers,
      }
    )
    this.name = 'NetworkTimeoutError'
  }
}
export class FirstFetchTimeoutError extends NetworkTimeoutError {
  constructor(message?: string, cause?: Error, duration?: number, headers?: Headers) {
    const headersArray: string[] = headers ? Array.from(headers).map((i) => `${i[0]}:${i[1]}`) : []
    super(message, cause, duration, headersArray)
    this.name = 'FirstFetchTimeoutError'
  }
}
export class ChunkFetchTimeoutError extends NetworkTimeoutError {
  constructor(message?: string, cause?: Error, duration?: number, headers?: Headers) {
    const headersArray: string[] = headers ? Array.from(headers).map((i) => `${i[0]}:${i[1]}`) : []
    super(message, cause, duration, headersArray)
    this.name = 'ChunkFetchTimeoutError'
  }
}

export class ConnectionTimeoutError extends NetworkTimeoutError {
  constructor(message?: string, cause?: Error, duration?: number, headers?: Headers) {
    const headersArray: string[] = headers ? Array.from(headers).map((i) => `${i[0]}:${i[1]}`) : []
    super(message, cause, duration, headersArray)
    this.name = 'ConnectionTimeoutError'
  }
}

export class TokenRateLimitingError extends NetworkTimeoutError {
  constructor(message?: string, cause?: Error, duration?: number, headers?: Headers) {
    const headersArray: string[] = headers ? Array.from(headers).map((i) => `${i[0]}:${i[1]}`) : []
    super(message, cause, duration, headersArray)
    this.name = 'TokenRateLimitingError'
  }
}

export class UploadError extends Error {
  constructor(message?: string, cause?: Error) {
    super(message, { cause })
    this.name = 'UploadError'
  }
}

export class EmptyError extends Error {
  constructor(message?: string, cause?: Error) {
    super(message, { cause })
    this.name = 'EmptyError'
  }
}

export class UploadSchemaError extends Error {
  constructor(message: string, cause?: Error) {
    super(message, { cause })
    this.name = 'UploadSchemaError'
  }
}
export class AssessmentError extends Error {
  errorData?: API.AssessmentAPI.ErrorData
  constructor(message: string, cause?: Error, errorData?: API.AssessmentAPI.ErrorData) {
    super(message, { cause })
    this.name = 'AssessmentError'
    this.errorData = errorData
  }
}

export class AssessmentSchemaError extends Error {
  constructor(message: string, cause?: Error) {
    super(message, { cause })
    this.name = 'AssessmentSchemaError'
  }
}

export class TranslationError extends Error {
  errorStreamMessage?: API.ErrorStreamMessage[]
  constructor(message: string, errorStreamMessage?: API.ErrorStreamMessage[]) {
    super(message)
    this.name = 'TranslationError'
    this.errorStreamMessage = errorStreamMessage
  }
}
