import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SingleValue } from 'react-select'
import { useDisclosure } from '@chakra-ui/hooks'
import {
  API,
  DeleteKBotResponse,
  DualLanguageOptionalValues,
  DualLanguageRequiredValues,
  GetKBotResponse,
  KBotBaseConfig,
  KBotConfig,
  ListKBotsResponse,
  SaveKBotResponse,
  SourceType,
} from '@kleo/types'
import * as Yup from 'yup'

import { useFetcher } from 'hooks/useFetcher'
import { useValidation } from 'hooks/useValidation'

import { NetworkError } from 'utils/appError'
import { fetchData } from 'utils/http/methods'
import {
  collectAllPromptsFromSubPrompts,
  fillMissingLanguageField,
  findIconInPrompts,
  nullForEmptyStrings,
} from 'utils/KBotsUtils'
import { createKBotFormValuesSchema, createKBotUploadJSONValuesSchema } from 'utils/kBotValidationSchemas'

import type {
  GetKBotFormValue,
  KBotBEConfig,
  KBotFormState,
  KBotFormValues,
  SearchFilterItem,
  SetKBotFormValue,
  ValidationErrors,
} from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'
import { useUploadContext } from './UploadProvider'

const defaultFormState = {
  description: [
    {
      en: null,
      fr: null,
    },
  ],
  fileContent: undefined,
  fixedName: undefined,
  icon: undefined,
  instructions: '',
  name: {
    en: '',
    fr: '',
  },
  starterPrompts: {
    category: {
      en: '',
      fr: '',
    },
    icon: undefined,
    prompts: [],
  },
  temperature: '0.0' as API.OpenAITemperature,
  templateId: undefined,
}

const maxDescriptionCharacters = 3000

type KBotLocalSettings = {
  template: SearchFilterItem | null
}

type KBotContextType = {
  clearFormState: (state: KBotFormState) => void
  createFormErrors: ValidationErrors<KBotFormValues>
  createValidate: (values: KBotFormValues) => Promise<void>
  deleteKBot: (kBotConfig: KBotConfig, onCloseDel: () => void, onClose: () => void) => void
  downloadKBot: (kBotConfig: KBotConfig) => void
  editFormErrors: ValidationErrors<KBotFormValues>
  editSelectedBot: KBotBaseConfig | null
  editValidate: (values: KBotFormValues) => Promise<void>
  fetchIndividualKBotDataError: 'unableToFetchKBotDetails' | null
  fetchKBotsError: string | null
  generalConfig: {
    supportedFileExtensions: string[]
    supportedMimeTypes: string[]
    uploadTokenMaxSize: number
  }
  getIndividualKBotData: (source: SourceType, name: string) => Promise<KBotConfig | null>
  getKBotFormValue: GetKBotFormValue
  getKBotFormValues: (state: KBotFormState) => KBotFormValues
  getKBotTemplate: (templateId: string) => DualLanguageRequiredValues | undefined
  getSelectedBot: (state: KBotFormState) => KBotBaseConfig | null
  getTranslatedValue: (langValues: DualLanguageRequiredValues | DualLanguageOptionalValues) => string
  handleBotSelect: (e: SingleValue<SearchFilterItem>, state: KBotFormState) => void
  isDeletingKBot: boolean
  isDownloadingKBot: boolean
  isFetchingIndividualKBotData: boolean
  isFetchingKBots: boolean
  isJSONUploading: boolean
  isJSONUploadOpen: boolean
  kBotData: ListKBotsResponse | null
  kBotForm: KBotFormValues | null
  kBots: KBotBaseConfig[] | undefined
  kBotSelectionsDetails: KBotConfig | null
  localKBotSelections: KBotLocalSettings
  localKBotSelectionsDetails: KBotConfig | null
  maxDescriptionCharacters: number
  onJSONUpload: (files: File[], state: KBotFormState, uploadTokenMaxSize: number) => void
  onJSONUploadClose: () => void
  onJSONUploadOpen: () => void
  saveKBot: ({ state, payload }: { state: KBotFormState; payload: KBotFormValues }) => Promise<SaveKBotResponse>
  setEditSelectedBot: Dispatch<SetStateAction<KBotBaseConfig | null>>
  setFetchingIndividualKBotDataError: Dispatch<SetStateAction<'unableToFetchKBotDetails' | null>>
  setIsJSONUploading: Dispatch<SetStateAction<boolean>>
  setKBotForm: Dispatch<SetStateAction<KBotFormValues | null>>
  setKBotFormValue: SetKBotFormValue
  setKBotSelectionsDetails: Dispatch<SetStateAction<KBotConfig | null>>
  setLocalKBotSelections: Dispatch<SetStateAction<KBotLocalSettings>>
  setLocalKBotSelectionsDetails: Dispatch<SetStateAction<KBotConfig | null>>
  setShouldFetchKBots: Dispatch<SetStateAction<boolean>>
  setUploadJSONError: Dispatch<SetStateAction<string>>
  systemKBotOptions: SearchFilterItem[] | undefined
  systemKBots: KBotBaseConfig[]
  uploadJSONError: string
  userKBotOptions: SearchFilterItem[] | undefined
}

export const KBotContext = createContext<KBotContextType>({} as KBotContextType)

type KBotsProviderProps = {
  children?: React.ReactNode
  config?: KBotBEConfig
}

export const KBotsProvider = ({ children, config }: KBotsProviderProps) => {
  const { t, languageAbbreviation } = useI18Context()
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { setDocContents, deleteDocumentContent } = useUploadContext()

  const { isOpen: isJSONUploadOpen, onOpen: onJSONUploadOpen, onClose: onJSONUploadClose } = useDisclosure()
  const {
    formErrors: createFormErrors,
    validate: createValidate,
    clearErrors: createClearErrors,
  } = useValidation<KBotFormValues>(
    createKBotFormValuesSchema(maxDescriptionCharacters, config?.be.UPLOAD_TOKEN_MAX_SIZE ?? 0)
  )
  const {
    formErrors: editFormErrors,
    validate: editValidate,
    clearErrors: editClearErrors,
  } = useValidation<KBotFormValues>(
    createKBotFormValuesSchema(maxDescriptionCharacters, config?.be.UPLOAD_TOKEN_MAX_SIZE ?? 0)
  )
  const [kBotForm, setKBotForm] = useState<KBotFormValues | null>(null)
  const [shouldFetchKBots, setShouldFetchKBots] = useState(false)
  const [fetchKBotsError, setFetchKBotsError] = useState<string | null>(null)
  // select for create and edit form
  const [createSelectedBot, setCreateSelectedBot] = useState<KBotBaseConfig | null>(null)
  const [editSelectedBot, setEditSelectedBot] = useState<KBotBaseConfig | null>(null)

  const [isJSONUploading, setIsJSONUploading] = useState(false)
  const [uploadJSONError, setUploadJSONError] = useState('')

  const [isDeletingKBot, setIsDeletingKBot] = useState(false)
  const [isDownloadingKBot, setIsDownloadingKBot] = useState(false)

  const { data: kBotData, isLoading: isFetchingKBots } = useFetcher<ListKBotsResponse>({
    allowRefetch: true,
    url: '/chatapi/listKBotsApi',
    shouldFetch: shouldFetchKBots,
    noCache: true,
    payload: {
      language: 'EN',
    },
    onError: (e) => {
      e instanceof NetworkError && setFetchKBotsError('fetchKBotsError')
    },
    onSuccess: () => {
      setFetchKBotsError(null)
    },
    final: () => {
      setShouldFetchKBots(false)
    },
  })

  const [kBotCreateFormState, setKBotCreateFormState] = useState<KBotFormValues>(defaultFormState)
  const [kBotEditFormState, setKBotEditFormState] = useState<KBotFormValues>(defaultFormState)

  const [kBotSelectionsDetails, setKBotSelectionsDetails] = useState<KBotConfig | null>(null)
  const [localKBotSelections, setLocalKBotSelections] = useState<KBotLocalSettings>({ template: null })
  const [localKBotSelectionsDetails, setLocalKBotSelectionsDetails] = useState<KBotConfig | null>(null)

  const [isFetchingIndividualKBotData, setIsFetchingIndividualKBotData] = useState<boolean>(false)
  const [fetchIndividualKBotDataError, setFetchingIndividualKBotDataError] = useState<
    'unableToFetchKBotDetails' | null
  >(null)

  const getIndividualKBotData = useCallback(
    async (source: SourceType, name: string) => {
      setFetchingIndividualKBotDataError(null)
      setIsFetchingIndividualKBotData(true)
      try {
        const token = await getToken()
        const response = await fetchData<GetKBotResponse>({
          url: `${API_ENDPOINT}/chatapi/getKBotApi`,
          token,
          payload: {
            name,
            source,
            language: 'EN',
          },
          retries: 1,
          rId: `${Math.random()}-${new Date().getTime()}`,
        })
        return response.botConfig
      } catch (e) {
        if (e instanceof NetworkError) {
          setFetchingIndividualKBotDataError('unableToFetchKBotDetails')
        }
        return null
      } finally {
        setIsFetchingIndividualKBotData(false)
      }
    },
    [API_ENDPOINT, getToken]
  )

  const fetchKBotAndSetForm = useCallback(
    async (source: SourceType, name: string, mode: string) => {
      let setter: Dispatch<SetStateAction<KBotFormValues>>
      if (mode === 'create') {
        setter = setKBotCreateFormState
      } else {
        setter = setKBotEditFormState
      }
      const kBotConfig = await getIndividualKBotData(source, name)

      if (kBotConfig) {
        const {
          name,
          description,
          fileContent,
          kbotTemperature,
          starterPrompts,
          template,
          templateId,
          userInstructions,
        } = kBotConfig

        const icon = findIconInPrompts(starterPrompts)
        const prompts = collectAllPromptsFromSubPrompts(starterPrompts)

        setter({
          icon: template.icon,
          templateId,
          name: template,
          fixedName: mode === 'create' ? undefined : name,
          instructions: userInstructions,
          description,
          starterPrompts: {
            category: template,
            icon,
            // Limit the prompts to the first 10 entries
            prompts: prompts.slice(0, 10),
          },
          temperature: kbotTemperature,
        })

        if (mode === 'edit') {
          editClearErrors()
        } else {
          createClearErrors()
        }

        if (fileContent && fileContent.length > 0) {
          setDocContents((prevDocContents) => ({
            ...prevDocContents,
            KBOTS: {
              ...prevDocContents['KBOTS'],
              [`${mode}KBotTemp`]: {
                text: fileContent,
                documentId: '',
                characterCount: fileContent.length,
                size: 0,
                type: '',
                usedOCR: false,
              },
            },
          }))
        } else {
          deleteDocumentContent('KBOTS', [`${mode}KBotTemp`])
        }
      }
    },
    // Can't add editClearErrors and createClearErrors to the dependency array, otherwise we run into infinite useEffect call loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIndividualKBotData, setDocContents]
  )

  // bot dropdown select for create form
  useEffect(() => {
    if (createSelectedBot) {
      const { source: selectedSource, name: selectedName } = createSelectedBot
      fetchKBotAndSetForm(selectedSource, selectedName, 'create')
    }
  }, [createSelectedBot, fetchKBotAndSetForm, setDocContents])

  // bot dropdown select for edit form
  useEffect(() => {
    if (editSelectedBot) {
      const { source: selectedSource, name: selectedName } = editSelectedBot
      fetchKBotAndSetForm(selectedSource, selectedName, 'edit')
    }
  }, [editSelectedBot, fetchKBotAndSetForm, setDocContents])

  const getKBotFormValues = useCallback(
    (state: KBotFormState) => (state === 'edit' ? kBotEditFormState : kBotCreateFormState),
    [kBotCreateFormState, kBotEditFormState]
  )

  const getSelectedBot = useCallback(
    (state: KBotFormState) => (state === 'edit' ? editSelectedBot : createSelectedBot),
    [createSelectedBot, editSelectedBot]
  )

  const getKBotFormValue = useCallback(
    <K extends keyof KBotFormValues>(field: K, state: KBotFormState): KBotFormValues[K] =>
      state === 'edit' ? kBotEditFormState[field] : kBotCreateFormState[field],
    [kBotCreateFormState, kBotEditFormState]
  )

  const setKBotFormValue = useCallback(
    <K extends keyof KBotFormValues>(
      field: K,
      valueOrUpdater: KBotFormValues[K] | ((prevValue: KBotFormValues[K]) => KBotFormValues[K]),
      state: KBotFormState
    ) => {
      const updateFormState = (prevState: KBotFormValues) => ({
        ...prevState,
        [field]:
          typeof valueOrUpdater === 'function'
            ? (valueOrUpdater as (prevValue: KBotFormValues[K]) => KBotFormValues[K])(prevState[field])
            : valueOrUpdater,
      })

      if (state === 'edit') {
        setKBotEditFormState((formState) => updateFormState(formState))
      } else {
        setKBotCreateFormState((formState) => updateFormState(formState))
      }
    },
    []
  )

  const getKBotTemplate = useCallback(
    (templateId: string) => {
      const configTemplate = kBotData?.botConfigs?.find((botConfig) => botConfig.templateId === templateId)?.template
      return configTemplate
    },
    [kBotData?.botConfigs]
  )

  const systemKBots = useMemo(() => {
    if (kBotData) {
      const kBotOptionsToSet = kBotData.botConfigs.filter((config) => config.source === 'kleo')

      return kBotOptionsToSet
    }
    return []
  }, [kBotData])

  const systemKBotOptions = useMemo(() => {
    return systemKBots
      .map((config) => ({
        label: config.template[languageAbbreviation],
        value: config.templateId,
      }))
      .sort((a, b) => Number(b.label === 'general') - Number(a.label === 'general'))
  }, [languageAbbreviation, systemKBots])

  const userKBotOptions = useMemo(() => {
    if (kBotData) {
      const kBotOptionsToSet: SearchFilterItem[] = kBotData.botConfigs
        .filter((config) => config.source === 'user')
        .map((config) => ({
          label: config.template[languageAbbreviation],
          value: config.templateId,
        }))
      return kBotOptionsToSet
    }
  }, [kBotData, languageAbbreviation])

  const { supportedFileExtensions, supportedMimeTypes, uploadTokenMaxSize } = useMemo(() => {
    if (!config) {
      return {
        supportedFileExtensions: [],
        supportedMimeTypes: [],
        uploadTokenMaxSize: 0,
        maxInput: 100000,
      }
    }

    const {
      UPLOAD_TOKEN_MAX_SIZE: uploadTokenMaxSize,
      SUPPORTED_MIME_TYPES: supportedMimeTypes,
      SUPPORTED_FILE_EXTENSIONS: supportedFileExtensions,
    } = config.be

    return {
      supportedFileExtensions: supportedFileExtensions ?? [],
      supportedMimeTypes: supportedMimeTypes ?? [],
      uploadTokenMaxSize: uploadTokenMaxSize ?? 0,
    }
  }, [config])

  const localSelectedKBotTemplate = useMemo(() => {
    if (kBotData && localKBotSelections.template !== null) {
      return (
        [...(userKBotOptions || []), ...(systemKBotOptions || [])]?.find(
          (option) => option.value === localKBotSelections.template?.value
        ) ?? null
      )
    }
    return null
  }, [kBotData, localKBotSelections.template, systemKBotOptions, userKBotOptions])

  // update KBot template value if language switches
  useEffect(() => {
    if (languageAbbreviation) {
      setLocalKBotSelections({
        template: localSelectedKBotTemplate,
      })
    }
  }, [languageAbbreviation, localSelectedKBotTemplate])

  // Auto-select the General config for the K-Bot selection modal on Page load
  useEffect(() => {
    const preSelectGeneralKBot = async (kBotData: ListKBotsResponse) => {
      const matchedConfig = kBotData.botConfigs.find((config) => config.name.toLowerCase() === 'general')
      if (matchedConfig) {
        const kBotDetails = await getIndividualKBotData(matchedConfig.source, matchedConfig.name)
        setKBotSelectionsDetails(kBotDetails)
        setLocalKBotSelectionsDetails(kBotDetails)
        setLocalKBotSelections({
          template: systemKBotOptions?.find((option) => option.value === matchedConfig.templateId) ?? null,
        })
      }
    }
    if (kBotData && kBotSelectionsDetails === null && localKBotSelections?.template === null) {
      preSelectGeneralKBot(kBotData)
    }
  }, [
    kBotData,
    systemKBotOptions,
    localKBotSelections?.template,
    kBotSelectionsDetails,
    getIndividualKBotData,
    languageAbbreviation,
    localSelectedKBotTemplate,
  ])

  const getTranslatedValue = useCallback(
    (langValues: DualLanguageRequiredValues | DualLanguageOptionalValues) => {
      const languageValue = langValues[languageAbbreviation]

      if (!languageValue) {
        return ''
      }

      return languageValue
    },
    [languageAbbreviation]
  )

  const handleBotSelect = useCallback(
    (e: SingleValue<SearchFilterItem>, state: KBotFormState) => {
      if (kBotData) {
        const matchedConfig = kBotData.botConfigs.find((config) => config.templateId === e?.value)
        if (matchedConfig) {
          if (state === 'edit') {
            setEditSelectedBot(matchedConfig)
          } else {
            setCreateSelectedBot(matchedConfig)
          }

          // Bot selection has changed, so we need to clear the contents of the upload section.
          setDocContents((prevDocContents) => {
            const { [state === 'edit' ? 'editKBotTemp' : 'createKBotTemp']: _, ...rest } = prevDocContents

            return rest
          })
        }
      }
    },
    [kBotData, setDocContents]
  )

  const onJSONUpload = useCallback(
    async (files: File[], state: KBotFormState, uploadTokenMaxSize: number) => {
      const file = files[0] // Access the first file from the array

      // Check if the file size exceeds 1.5MB (1.5MB = 1.5 * 1024 * 1024 bytes)
      const maxFileSize = 1.5 * 1024 * 1024 // 1.5MB in bytes
      if (file.size > maxFileSize) {
        setUploadJSONError('uploadUrlEndpointFailed')
        return // Exit the function early to prevent further processing
      }

      if (file && file.type === 'application/json') {
        const reader = new FileReader()

        reader.onload = async (e) => {
          try {
            setIsJSONUploading(true)

            // Delay the execution of the rest of the code by 1 second, that way the user can visually see the Uploading animations
            await new Promise((resolve) => setTimeout(resolve, 1000))

            const fileText = e.target?.result as string
            const parsedJson: KBotConfig = JSON.parse(fileText)

            // Validate the parsed JSON against the Yup schema
            createKBotUploadJSONValuesSchema(t)
              .validate(parsedJson, { strict: true, abortEarly: true }) // Use Yup to validate, abortEarly: true to catch one error at a time
              .then((result) => {
                const icon = findIconInPrompts(result.starterPrompts || [])
                const fileContentToUse =
                  result.fileContent && result.fileContent.length < uploadTokenMaxSize * 4
                    ? result.fileContent
                    : undefined

                // If validation passes, update the state with the valid config
                const kBotConfig = {
                  templateId: undefined,
                  name: result.template,
                  instructions: result.userInstructions || '',
                  description: result.description || [{ en: '', fr: '' }],
                  starterPrompts: {
                    category: result.template,
                    icon,
                    prompts: collectAllPromptsFromSubPrompts(result.starterPrompts || []).slice(0, 10),
                  },
                  temperature: result.kbotTemperature || ('0.0' as API.OpenAITemperature),
                  fileContent: fileContentToUse || undefined,
                }
                if (state === 'edit') {
                  setKBotEditFormState((current) => {
                    return { ...current, ...kBotConfig }
                  })
                } else {
                  setKBotCreateFormState((current) => {
                    return { ...current, ...kBotConfig }
                  })
                }

                // Set the fileContent values as part of the useState kept in UploadProvider
                if (fileContentToUse && fileContentToUse.length > 0) {
                  setDocContents((prevDocContents) => ({
                    ...prevDocContents,
                    KBOTS: {
                      ...prevDocContents['KBOTS'],
                      [`${state}KBotTemp`]: {
                        text: fileContentToUse,
                        documentId: '',
                        characterCount: fileContentToUse.length,
                        size: 0,
                        type: '',
                        usedOCR: false,
                      },
                    },
                  }))
                } else {
                  deleteDocumentContent('KBOTS', [`${state}KBotTemp`])
                }

                onJSONUploadClose()
              })
              .catch((err: Yup.ValidationError) => {
                // Extract the error message(s)
                const errorMessage = err.errors[0] // Access the first error message
                setUploadJSONError(errorMessage)
              })
          } catch (error) {
            setUploadJSONError('corruptUploadError')
          } finally {
            setIsJSONUploading(false)
          }
        }

        reader.onerror = () => {
          console.error('File could not be read')
        }

        reader.readAsText(file) // Read the file as text
      } else {
        console.error('Please upload a valid JSON file')
      }
    },
    [deleteDocumentContent, onJSONUploadClose, setDocContents, t]
  )

  const clearFormState = useCallback(
    (state: KBotFormState) => {
      if (state === 'edit') {
        setEditSelectedBot(null)
        setKBotEditFormState(defaultFormState)
      } else {
        setCreateSelectedBot(null)
        setKBotCreateFormState(defaultFormState)
      }
      setDocContents((prevDocContents) => {
        const { [state === 'edit' ? 'editKBotTemp' : 'createKBotTemp']: _, ...rest } = prevDocContents

        return rest
      })
    },
    [setDocContents]
  )

  const saveKBot = async ({ state, payload }: { state: KBotFormState; payload: KBotFormValues }) => {
    const token = await getToken()

    const filledNames = fillMissingLanguageField(payload.name)
    const filledStarterPrompt = {
      ...payload.starterPrompts,
      category:
        !payload.starterPrompts.category.en && !payload.starterPrompts.category.fr
          ? filledNames
          : fillMissingLanguageField(payload.starterPrompts.category),
      prompts: nullForEmptyStrings(payload.starterPrompts.prompts),
    }

    const response = await fetchData<SaveKBotResponse>({
      url: `${API_ENDPOINT}/chatapi/saveKBotApi`,
      token,
      payload: {
        botConfig: {
          template: { ...filledNames, icon: payload.icon },
          description: nullForEmptyStrings(payload.description),
          userInstructions: payload.instructions,
          fileContent: payload.fileContent ?? '',
          starterPrompts: [filledStarterPrompt],
          kbotTemperature: payload.temperature,
          source: SourceType.user,
          ...(state === 'edit' ? { templateId: payload.templateId, name: payload.fixedName } : {}),
        },
        language: 'EN',
      },
      retries: 0,
      rId: `${Math.random()}-${new Date().getTime()}`,
    })

    return response
  }

  const downloadKBot = (kBotConfig: KBotConfig) => {
    try {
      setIsDownloadingKBot(true)
      const { templateId, author, date, source, ...pIIOmittedKBotConfig } = kBotConfig
      const dataStr =
        'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(pIIOmittedKBotConfig, null, 2))
      const downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', `${kBotConfig.template[languageAbbreviation]}.json`)
      document.body.appendChild(downloadAnchorNode) // Required for FireFox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    } catch (e) {
      console.error('Something went wrong when downloading', e)
    } finally {
      setIsDownloadingKBot(false)
    }
  }

  const deleteKBot = async (kBotConfig: KBotConfig, onCloseDel: () => void, onClose: () => void) => {
    try {
      setIsDeletingKBot(true)
      const token = await getToken()
      const response = await fetchData<DeleteKBotResponse>({
        url: `${API_ENDPOINT}/chatapi/deleteKBotApi`,
        token,
        payload: {
          templateId: kBotConfig.templateId,
          language: 'EN',
        },
        retries: 0,
        rId: `${Math.random()}-${new Date().getTime()}`,
      })
      if (response) {
        if (kBotConfig.templateId === localKBotSelectionsDetails?.templateId && kBotData) {
          // If the user has selected in the K-Bot selection modal the K-Bot that we are deleting, unselect it and default to General if available
          const matchedConfig = kBotData.botConfigs.find((config) => config.name.toLowerCase() === 'general')
          if (matchedConfig) {
            const kBotDetails = await getIndividualKBotData(matchedConfig.source, matchedConfig.name)
            setLocalKBotSelectionsDetails(kBotDetails)
            setLocalKBotSelections({
              template: systemKBotOptions?.find((option) => option.value === matchedConfig.templateId) ?? null,
            })
          } else {
            setLocalKBotSelectionsDetails(null)
            setLocalKBotSelections({ template: null })
          }
        }
        setShouldFetchKBots(true)
        onCloseDel()
        onClose()
      }
    } catch (e) {
      console.error('Something went wrong when deleting', e)
    } finally {
      setIsDeletingKBot(false)
    }
  }

  return (
    <>
      <KBotContext.Provider
        value={{
          clearFormState,
          createFormErrors,
          createValidate,
          deleteKBot,
          downloadKBot,
          editFormErrors,
          editSelectedBot,
          editValidate,
          fetchIndividualKBotDataError,
          fetchKBotsError,
          generalConfig: { supportedFileExtensions, supportedMimeTypes, uploadTokenMaxSize },
          getIndividualKBotData,
          getKBotFormValue,
          getKBotFormValues,
          getKBotTemplate,
          getSelectedBot,
          getTranslatedValue,
          handleBotSelect,
          isDeletingKBot,
          isDownloadingKBot,
          isFetchingIndividualKBotData,
          isFetchingKBots,
          isJSONUploading,
          isJSONUploadOpen,
          kBotData,
          kBotForm,
          kBots: kBotData?.botConfigs,
          kBotSelectionsDetails,
          localKBotSelections,
          localKBotSelectionsDetails,
          maxDescriptionCharacters,
          onJSONUpload,
          onJSONUploadClose,
          onJSONUploadOpen,
          saveKBot,
          setEditSelectedBot,
          setFetchingIndividualKBotDataError,
          setIsJSONUploading,
          setKBotForm,
          setKBotFormValue,
          setKBotSelectionsDetails,
          setLocalKBotSelections,
          setLocalKBotSelectionsDetails,
          setShouldFetchKBots,
          setUploadJSONError,
          systemKBotOptions,
          systemKBots,
          uploadJSONError,
          userKBotOptions,
        }}
      >
        {children}
      </KBotContext.Provider>
    </>
  )
}

export const useKBotContext = (): KBotContextType => useContext(KBotContext)
