import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import {
  BatchStatus,
  DeleteAPIResponse,
  FolderData,
  GetFolderViewAPIResponse,
  GetTranslateUrlUploadRes,
  TargetLanguage,
  TranslateAPIResponse,
  TranslateBatchStatusAPIResponse,
} from '@kleo/types'
import { saveAs } from 'file-saver'

import { TranslationError } from 'utils/appError'
import { formatMaxFileSize } from 'utils/formatter'
import { getFileName } from 'utils/http/file'
import { fetchData, fetchDataRaw, putFileIntoStorage, timeOutAction } from 'utils/http/methods'

import type { FEUIConfig, SortingOrder } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

export type UploadFile = {
  type: string
  name: string
  bytes: number
  dateTime: number
}

export type UploadFilesByLanguage = Record<TargetLanguage, UploadFile[]>

type TranslationContextType = {
  checkedItems: number[]
  deleteTranslations: (files: string[]) => Promise<void>
  deleteTranslationsError: boolean
  downloadTranslations: (files: string[]) => Promise<void>
  fetchFolderError: boolean
  fetchFolders: (userRefresh?: boolean) => Promise<number | undefined>
  fetchFolderStatus: (requestId: string) => Promise<void>
  folderData: FolderData[] | null
  folderStatus: BatchStatus | null
  isDownloadingTranslations: boolean
  isFetchingFolders: boolean
  isFileLevel: boolean
  isFileUploading: boolean
  isFolderLevel: boolean
  isGridView: boolean
  isOpenReceipt: boolean
  isRefreshing: boolean
  isTranslationCompleted: boolean
  isWarningAcknowledged1: boolean
  isWarningAcknowledged2: boolean
  maxFileSize: string
  onCloseReceipt: () => void
  onOpenReceipt: () => void
  refreshError: boolean
  resetUploadedFiles: () => void
  setCheckedItems: Dispatch<SetStateAction<number[]>>
  setDeleteTranslationsError: Dispatch<SetStateAction<boolean>>
  setFetchFolderError: Dispatch<SetStateAction<boolean>>
  setFolderStatus: Dispatch<SetStateAction<BatchStatus | null>>
  setIsDownloadingTranslations: Dispatch<SetStateAction<boolean>>
  setIsFetchingFolders: Dispatch<SetStateAction<boolean>>
  setIsFileUploading: Dispatch<SetStateAction<boolean>>
  setIsGridView: Dispatch<SetStateAction<boolean>>
  setIsRefreshing: Dispatch<SetStateAction<boolean>>
  setIsTranslationCompleted: Dispatch<SetStateAction<boolean>>
  setIsWarningAcknowledged1: Dispatch<SetStateAction<boolean>>
  setIsWarningAcknowledged2: Dispatch<SetStateAction<boolean>>
  setRefreshError: Dispatch<SetStateAction<boolean>>
  setShowFetchFolderErrorMessage: Dispatch<SetStateAction<boolean>>
  setShowFolderSkeleton: Dispatch<SetStateAction<boolean>>
  setSortingOrder: Dispatch<SetStateAction<SortingOrder[]>>
  setTier1: Dispatch<SetStateAction<string | null>>
  setTranslationError: Dispatch<SetStateAction<string[] | null>>
  setUploadedFiles: Dispatch<SetStateAction<UploadFilesByLanguage>>
  showFetchFolderErrorMessage: boolean
  showFolderSkeleton: boolean
  sortingOrder: SortingOrder[]
  tier1: string | null
  totalFiles: number
  translationError: string[] | null
  translationProgress: null | number
  updateUploadedFiles: (newFiles: UploadFile[], lang: TargetLanguage) => void
  uploadedFiles: UploadFilesByLanguage
  uploadFileMaxCount: number
  uploadFilesRequest: (folderName: string, targetLanguage: TargetLanguage, files: File[]) => void
  uploadFolderName: string
}

export const TranslationContext = createContext<TranslationContextType>({} as TranslationContextType)

type TranslationProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const TranslationProvider = ({ children, config }: TranslationProviderProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { t, language } = useI18Context()

  const { isOpen: isOpenReceipt, onOpen: onOpenReceipt, onClose: onCloseReceipt } = useDisclosure()

  const [translationProgress] = useState<null | number>(null)
  const [isWarningAcknowledged1, setIsWarningAcknowledged1] = useState<boolean>(false)
  const [isWarningAcknowledged2, setIsWarningAcknowledged2] = useState<boolean>(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<UploadFilesByLanguage>({
    [TargetLanguage.en]: [],
    [TargetLanguage.fr]: [],
  })
  const [uploadFolderName, setUploadFolderName] = useState<string>('')

  const [tier1, setTier1] = useState<string | null>(null)
  const [checkedItems, setCheckedItems] = useState<Array<number>>([])
  const [isGridView, setIsGridView] = useState<boolean>(false)
  const [folderData, setFolderData] = useState<FolderData[] | null>(null)
  const [folderStatus, setFolderStatus] = useState<BatchStatus | null>(null)

  const [translationError, setTranslationError] = useState<string[] | null>(null)
  const [isTranslationCompleted, setIsTranslationCompleted] = useState(false)
  const [sortingOrder, setSortingOrder] = useState<SortingOrder[]>([])

  // reset to folder level on unmount
  useEffect(() => {
    return () => {
      setTier1(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isFolderLevel = useMemo(() => {
    return !tier1
  }, [tier1])

  const isFileLevel = useMemo(() => {
    return !!tier1
  }, [tier1])

  const resetUploadedFiles = useCallback((): void => {
    setUploadedFiles({
      [TargetLanguage.en]: [],
      [TargetLanguage.fr]: [],
    })
  }, [])

  const { uploadFileMaxCount, uploadFileMaxSize } = useMemo(() => {
    const translationBotConfig = config?.find((botConfig) => botConfig.type === 'translation')

    if (!translationBotConfig) {
      return {
        uploadFileMaxCount: 0,
        uploadFileMaxSize: 0,
      }
    }

    return {
      uploadFileMaxCount: translationBotConfig.uploadFileMaxCount ?? 0,
      uploadFileMaxSize: translationBotConfig.uploadFileMaxSize ?? 0,
    }
  }, [config])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t)
  }, [uploadFileMaxSize, numberFormat, t])

  // loading states
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [isDownloadingTranslations, setIsDownloadingTranslations] = useState(false)
  const [showFolderSkeleton, setShowFolderSkeleton] = useState(false)
  const [isFetchingFolders, setIsFetchingFolders] = useState(false)

  // error states
  const [deleteTranslationsError, setDeleteTranslationsError] = useState(false)
  const [refreshError, setRefreshError] = useState(false)
  const [fetchFolderError, setFetchFolderError] = useState(false)
  const [showFetchFolderErrorMessage, setShowFetchFolderErrorMessage] = useState(false)

  useEffect(() => {
    setShowFetchFolderErrorMessage(fetchFolderError)
  }, [fetchFolderError])

  const totalFiles = useMemo(() => {
    if (folderData === null) return 0
    return folderData.reduce((total, folder) => total + folder.files.length, 0)
  }, [folderData])

  const uploadFilesRequest = async (folderName: string, targetLanguage: TargetLanguage, files: File[]) => {
    try {
      setIsFileUploading(true)
      await initializeUploadAndTranslate(folderName, targetLanguage, files)
    } catch (err: unknown) {
      if (
        err instanceof TranslationError &&
        err.errorStreamMessage &&
        Array.isArray(err.errorStreamMessage) &&
        err.errorStreamMessage.length > 0 &&
        err.errorStreamMessage[0].code
      ) {
        const errorCodes = err.errorStreamMessage.map((item) => item.code)
        setTranslationError(errorCodes)
      } else {
        setTranslationError(['generic'])
      }
    } finally {
      setIsFileUploading(false)
    }
  }

  const initializeUploadAndTranslate = async (folderName: string, targetLanguage: TargetLanguage, files: File[]) => {
    const rId = `${Math.random()}-${new Date().getTime()}`

    const token = await getToken()
    const filesPayload = files.map((item) => {
      return {
        contentType: item.type,
        fileSize: item.size,
        fileName: item.name,
      }
    })

    const urlUploadResponse = await fetchData<GetTranslateUrlUploadRes>({
      url: `${API_ENDPOINT}/translateapi/urlUpload`,
      token,
      payload: {
        language: 'EN',
        files: filesPayload,
        folderName,
      },
      rId,
    })

    if (urlUploadResponse && 'requestId' in urlUploadResponse && 'items' in urlUploadResponse) {
      setUploadFolderName(urlUploadResponse.requestId)
      const statusArray = []
      for (let i = 0; i < urlUploadResponse.items.length; i++) {
        const { status, statusText } = await putFileIntoStorageContainer({
          url: urlUploadResponse.items[i].url,
          file: files[i],
          rId,
        })
        statusArray.push({ status, statusText })
      }

      if (statusArray.every((element) => element.status === 201 && element.statusText === 'Created')) {
        try {
          setIsTranslationCompleted(false)
          const translateResponse = await fetchData<TranslateAPIResponse>({
            url: `${API_ENDPOINT}/translateapi/translate`,
            token,
            payload: {
              language: 'EN',
              folderId: urlUploadResponse.requestId,
              targetLanguage,
            },
            rId,
          })

          if (translateResponse && 'requestId' in translateResponse) {
            handledUploadedFiles(targetLanguage, files)
            onOpenReceipt()
          } else if (translateResponse && 'errorList' in translateResponse) {
            throw new TranslationError('translateApi-translate-request', translateResponse.errorList)
          }
        } finally {
          setIsTranslationCompleted(true)
        }
      } else {
        throw new TranslationError('translationApi-urlUpload-request')
      }
    } else if (urlUploadResponse && 'errorList' in urlUploadResponse) {
      throw new TranslationError('translationApi-urlUpload-request', urlUploadResponse.errorList)
    }
  }

  const putFileIntoStorageContainer = async ({ url, file, rId }: { url: string; file: File; rId: string }) => {
    try {
      const { status, statusText } = await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
        },
        rId,
      })

      return { status, statusText }
    } catch (e) {
      throw new Error('upload-request-error-documentUpload', e as Error)
    }
  }

  const updateUploadedFiles = useCallback(
    (newFiles: UploadFile[], lang: TargetLanguage): void => {
      resetUploadedFiles()
      setUploadedFiles((prevItems) => ({
        ...prevItems,
        [lang]: newFiles,
      }))
    },
    [resetUploadedFiles]
  )

  const handledUploadedFiles = useCallback(
    (targetLanguage: TargetLanguage, files: File[]) => {
      const filesToProcess: UploadFile[] = files.map((item) => {
        // handle edge case where filenames without extension or with multiple dots
        const parts = item.name.split('.')
        const fileType = parts.length > 1 && parts[parts.length - 1] ? parts.pop() : ''
        const currEpochTime = Math.floor(Date.now() / 1000)

        return {
          name: item.name,
          bytes: item.size || 0,
          status: 'uploading',
          type: fileType as string,
          dateTime: currEpochTime,
        }
      })
      updateUploadedFiles(filesToProcess, targetLanguage)
    },
    [updateUploadedFiles]
  )

  const fetchFolders = useCallback(
    async (userRefresh = false) => {
      const rId = `${Math.random()}-${new Date().getTime()}`
      try {
        setIsFetchingFolders(true)
        setRefreshError(false)
        setFetchFolderError(false)

        if (!folderData) {
          setShowFolderSkeleton(true)
        }
        const token = await getToken()
        const fetcher = fetchData<GetFolderViewAPIResponse>({
          url: `${API_ENDPOINT}/translateapi/getFolderView`,
          token,
          payload: {
            language: 'EN',
          },
          rId,
        })

        const { data } = await timeOutAction(fetcher, 2000, () => {
          throw new Error('fetch folder took longer than 1 second')
        })

        setFolderData(data)
        return data.reduce((total, folder) => total + folder.files.length, 0)
      } catch (e) {
        setFetchFolderError(true)
        if (userRefresh) {
          setRefreshError(true)
        }
      } finally {
        setIsFetchingFolders(false)
        setShowFolderSkeleton(false)
      }
    },
    [API_ENDPOINT, folderData, getToken, setFetchFolderError]
  )

  const deleteTranslations = useCallback(
    async (files: string[]) => {
      const rId = `${Math.random()}-${new Date().getTime()}`
      try {
        setDeleteTranslationsError(false)
        const token = await getToken()
        const { status } = await fetchData<DeleteAPIResponse>({
          url: `${API_ENDPOINT}/translateapi/delete`,
          token,
          payload: {
            language: 'EN',
            files,
          },
          rId,
        })

        if (status === 'ok') {
          await fetchFolders()
        }
      } catch (e) {
        setDeleteTranslationsError(true)
      }
    },
    [API_ENDPOINT, fetchFolders, getToken]
  )

  const downloadTranslations = useCallback(
    async (files: string[]) => {
      const rId = `${Math.random()}-${new Date().getTime()}`
      try {
        setIsDownloadingTranslations(true)
        const token = await getToken()
        const response = await fetchDataRaw<Response>({
          url: `${API_ENDPOINT}/translateapi/exportTranslationData`,
          token,
          payload: {
            language: 'EN',
            files,
          },
          rId,
        })

        if (response) {
          try {
            const blob = await response.blob()
            saveAs(blob, getFileName(response.headers))
          } catch (e) {
            console.error(e)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsDownloadingTranslations(false)
      }
    },
    [API_ENDPOINT, getToken]
  )

  const fetchFolderStatus = useCallback(
    async (requestId: string) => {
      const rId = `${Math.random()}-${new Date().getTime()}`
      try {
        const token = await getToken()
        const { status } = await fetchData<TranslateBatchStatusAPIResponse>({
          url: `${API_ENDPOINT}/translateapi/status`,
          token,
          payload: {
            language: 'EN',
            requestId,
          },
          rId,
        })

        setFolderStatus(status)
      } catch (e) {
        console.error(e)
      }
    },
    [API_ENDPOINT, getToken]
  )

  return (
    <>
      <TranslationContext.Provider
        value={{
          checkedItems,
          deleteTranslations,
          deleteTranslationsError,
          downloadTranslations,
          fetchFolderError,
          fetchFolders,
          fetchFolderStatus,
          folderData,
          folderStatus,
          isDownloadingTranslations,
          isFetchingFolders,
          isFileLevel,
          isFileUploading,
          isFolderLevel,
          isGridView,
          isOpenReceipt,
          isRefreshing,
          isTranslationCompleted,
          isWarningAcknowledged1,
          isWarningAcknowledged2,
          maxFileSize,
          onCloseReceipt,
          onOpenReceipt,
          refreshError,
          resetUploadedFiles,
          setCheckedItems,
          setDeleteTranslationsError,
          setFetchFolderError,
          setFolderStatus,
          setIsDownloadingTranslations,
          setIsFetchingFolders,
          setIsFileUploading,
          setIsGridView,
          setIsRefreshing,
          setIsTranslationCompleted,
          setIsWarningAcknowledged1,
          setIsWarningAcknowledged2,
          setRefreshError,
          setShowFetchFolderErrorMessage,
          setShowFolderSkeleton,
          setSortingOrder,
          setTier1,
          setTranslationError,
          setUploadedFiles,
          showFetchFolderErrorMessage,
          showFolderSkeleton,
          sortingOrder,
          tier1,
          totalFiles,
          translationError,
          translationProgress,
          updateUploadedFiles,
          uploadedFiles,
          uploadFileMaxCount,
          uploadFilesRequest,
          uploadFolderName,
        }}
      >
        {children}
      </TranslationContext.Provider>
    </>
  )
}

export const useTranslationContext = (): TranslationContextType => useContext(TranslationContext)
