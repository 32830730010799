import * as Yup from 'yup'

import { KBotFormValues, KBotUploadJSONValues } from 'types/types'

export const createNameEnglishOrFrenchSchema = () =>
  Yup.object()
    .shape({
      en: Yup.string().typeError('kBots.error.createEdit.invalidType'),
      fr: Yup.string().typeError('kBots.error.createEdit.invalidType'),
    })
    .test('either-en-or-fr', 'kBots.error.eitherLanguageRequired', (value) => {
      const { en, fr } = value
      return !!(en || fr)
    })

// Dual language schema for required fields
export const createDualLanguageRequiredSchema = (field: string) =>
  Yup.object().shape({
    en: Yup.string()
      .typeError(`kBots.error.${field}.invalidEnglishStructure`)
      .required(`kBots.error.${field}.englishRequired'`),
    fr: Yup.string()
      .typeError(`kBots.error.${field}.invalidFrenchStructure`)
      .required(`kBots.error.${field}.frenchRequired'`),
  })

// Dual language schema for optional fields
export const createDualLanguageOptionalSchema = (field: string) =>
  Yup.object().shape({
    en: Yup.string().typeError(`kBots.error.${field}.invalidEnglishStructure`).nullable(),
    fr: Yup.string().typeError(`kBots.error.${field}.invalidFrenchStructure`).nullable(),
  })

// Prompt Library Prompts Schema
export const createPromptLibraryPromptsSchema = () =>
  Yup.object()
    .shape({
      icon: Yup.string().optional(),
      category: createDualLanguageOptionalSchema('category'), // Required category
      prompts: Yup.array()
        .of(createDualLanguageOptionalSchema('prompts')) // Optional prompts in both languages
        .typeError('kBots.error.invalidPromptsStructure')
        .optional(),
    })
    .optional()

// Parent Prompts Schema for nested structures
export const createPromptLibraryParentPromptsSchema = () =>
  Yup.object().shape({
    icon: Yup.string().optional(),
    category: createDualLanguageRequiredSchema('category'), // Required category
    subPrompts: Yup.array().of(createPromptLibraryPromptsSchema()), // Nested structure for subPrompts
  })

// KBotFormValues schema
export const createKBotFormValuesSchema = (
  maxDescriptionCharacters: number,
  maxFileContentTokens: number
): Yup.ObjectSchema<KBotFormValues> =>
  Yup.object().shape({
    fixedName: Yup.mixed().notRequired(),
    icon: Yup.mixed().notRequired(),
    templateId: Yup.mixed().notRequired(),
    name: createNameEnglishOrFrenchSchema(),
    description: Yup.array()
      .of(createDualLanguageOptionalSchema('description')) // Optional dual-language description
      .test('description-total-max-length', 'kBots.error.descriptionMaxLengthExceeded', (descriptions) => {
        if (!descriptions) return true // If there's no description, the test passes.

        const totalLength = descriptions.reduce((acc, desc) => {
          const enLength = desc.en ? desc.en.length : 0
          const frLength = desc.fr ? desc.fr.length : 0
          return acc + enLength + frLength
        }, 0)

        return totalLength <= maxDescriptionCharacters
      })
      .optional(),
    instructions: Yup.string()
      .required('kBots.error.instructionsRequired')
      .max(8000, 'kBots.error.UserInstructionOverSize'),
    starterPrompts: createPromptLibraryPromptsSchema(),
    temperature: Yup.string().oneOf(['0.0', '0.5', '1.0']).required('kBots.error.temperatureRequired'),
    fileContent: Yup.string()
      .optional()
      .max(maxFileContentTokens * 4, 'kBots.error.FileContentTooBig'),
  }) as Yup.ObjectSchema<KBotFormValues>

//   KBotUploadJSONValues schema, for validating user uploaded K-Bot templates
export const createKBotUploadJSONValuesSchema = (t: (key: string) => string): Yup.ObjectSchema<KBotUploadJSONValues> =>
  Yup.object()
    .typeError('corruptUploadError')
    .shape({
      template: createDualLanguageRequiredSchema('template')
        .typeError('kBots.error.invalidTemplateStructure')
        .required('kBots.error.templateRequired'), // Required dual-language name
      description: Yup.array()
        .of(createDualLanguageOptionalSchema('description'))
        .typeError('kBots.error.invalidDescriptionStructure')
        .optional(),
      userInstructions: Yup.string()
        .typeError('kBots.error.invalidInstructionsStructure')
        .required('kBots.error.instructionsRequired'),
      starterPrompts: Yup.array()
        .of(
          Yup.lazy((value) =>
            value.subPrompts ? createPromptLibraryParentPromptsSchema() : createPromptLibraryPromptsSchema()
          )
        )
        .typeError('kBots.error.invalidStarterPromptsStructure')
        .optional(),
      kbotTemperature: Yup.string()
        .oneOf(['0.0', '0.5', '1.0'], 'kBots.error.invalidTemperatureValues')
        .typeError('kBots.error.invalidTemperatureStructure')
        .required('kBots.error.temperatureRequired'),
      fileContent: Yup.string().optional().typeError('kBots.error.invalidFileContentStructure'),
    }) as Yup.ObjectSchema<KBotUploadJSONValues>
