import { ReactElement, ReactNode, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { BiCloudUpload } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'

import { useI18Context } from 'providers/i18Provider'

import { GeneralSpinner } from 'router/Spinner'

import { TextButton } from './buttons/TextButton'
import { BannerProps } from './Banner'

type UploaderProps = {
  banner?: ReactElement<BannerProps>
  botName: string
  customClasses?: {
    container?: string
    uploadZone?: string
    button?: string
  }
  customMetricsMessaging?: ReactNode
  extensionTypes: string[]
  isDisabled?: boolean
  isFileUploading: boolean
  maxFileSize: string
  maxNumFiles: number
  onDrop: (acceptedFiles: File[]) => Promise<void>
  showInlineError?: boolean
  tooltip?: Omit<TooltipProps, 'children'>
  uploadError: string
  uploadModalClickMessageKey?: string
}

export const Uploader = ({
  banner,
  botName,
  customClasses,
  customMetricsMessaging,
  extensionTypes,
  isDisabled = false,
  isFileUploading,
  maxFileSize,
  maxNumFiles,
  onDrop,
  showInlineError = false,
  tooltip,
  uploadError,
  uploadModalClickMessageKey = 'uploadModal.clickMessage',
}: UploaderProps) => {
  const { t, doesTranslationExist, getTForNS } = useI18Context()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: isFileUploading || isDisabled })
  const botT = getTForNS(botName.toLocaleLowerCase())
  const uploadDragMessage = doesTranslationExist('uploadModal.dragMessage', botName.toLocaleLowerCase())
    ? botT('uploadModal.dragMessage')
    : t('uploadModal.dragMessage')

  const uploaderContent = useMemo(
    () => (
      <Box>
        <Box className="flex flex-col items-center justify-center mb-4 text-center">
          <BiCloudUpload className="w-6 h-6 mb-2 md:h-8 md:w-8" />
          <Text className="text-sm md:text-base">{uploadDragMessage}</Text>
          <Text className="p-1 text-xs md:text-sm">{t('uploadModal.or')}</Text>
          <TextButton
            className={`text-xs md:text-sm ${customClasses && customClasses.button && customClasses.button}`}
            isDisabled={isDisabled}
          >
            {t(uploadModalClickMessageKey)}
          </TextButton>
        </Box>
        <Box className="flex flex-col items-center justify-center text-center">
          <Text className="mb-2 text-xs">
            {t('uploadModal.maxFileSize')}:{' '}
            <Text as="span" className="font-bold">
              {/* If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB) */}
              {maxFileSize}
            </Text>
          </Text>
          <Text className="mb-2 text-xs">
            {t('translation.upload.modal.maxNumFiles')}:{' '}
            <Text as="span" className="text-xs font-bold">
              {maxNumFiles}
            </Text>
          </Text>
          {customMetricsMessaging && <Text className="mb-2 text-xs">{customMetricsMessaging}</Text>}
          <Text className="text-xs">
            {t('uploadModal.supportedFileTypes')}:{' '}
            <Text as="span" className="text-xs font-bold uppercase">
              {extensionTypes.length && extensionTypes.join(', ')}
            </Text>
          </Text>
          {uploadError && showInlineError && (
            <Text className="my-2 text-xs text-red-400">{t(`assessment.adhoc.error.${uploadError}`)}</Text>
          )}
        </Box>
      </Box>
    ),
    [
      customClasses,
      customMetricsMessaging,
      extensionTypes,
      isDisabled,
      maxFileSize,
      maxNumFiles,
      showInlineError,
      t,
      uploadDragMessage,
      uploadError,
      uploadModalClickMessageKey,
    ]
  )

  return (
    <>
      {banner && banner}
      <Box
        className={`w-full rounded-lg ${customClasses && customClasses.container && customClasses.container}`}
        {...getRootProps()}
      >
        <input {...getInputProps()} aria-label="file-upload" disabled={isDisabled} />
        <Box
          className={`p-4 text-sm border-2 border-dashed shadow-sm rounded-md  ${
            isDragActive ? 'border-solid' : 'border-dashed'
          } ${uploadError && 'border-red-400'} ${customClasses && customClasses.uploadZone && customClasses.uploadZone}`}
        >
          {isFileUploading ? (
            <Box className="flex flex-col items-center justify-center">
              <Text className="text-sm md:text-base">{t('uploadModal.uploading')}...</Text>
              <GeneralSpinner fullHeight={false} />
              <Text className="text-xs text-center md:text-sm">{t('uploadModal.uploadingMessaging')}</Text>
            </Box>
          ) : tooltip ? (
            <Tooltip {...tooltip}>{uploaderContent}</Tooltip>
          ) : (
            uploaderContent
          )}
        </Box>
      </Box>
    </>
  )
}
