import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  API,
  ClearChatMessagesResponse,
  ConversationConfigUpdateResponse,
  ConversationCreateResponse,
  ConversationDeleteResponse,
  DualLanguageOptionalValues,
  KBotConfig,
  SourceType,
  StarterPrompt,
  TemplateType,
} from '@kleo/types'
import { v4 as uuidv4 } from 'uuid'

import { fetchData } from 'utils/http/methods'

import type { ChatMessage, ConversationSettings, FEUIConfig } from 'types/types'

import { useAssessmentContext } from './AssessmentProvider'
import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'
import { useI18Context } from './i18Provider'

export type ConversationType = {
  created: number
  displayName: string | null
  haveMessagesBeenFetched: boolean
  isSaved: boolean
  messages: ChatMessage[]
  summary: Array<string> | null
  templateId?: string
} & Partial<ConversationKBotConfig>

export type ConversationKBotConfig = {
  description: DualLanguageOptionalValues[]
  fileContent: string
  kbotTemperature: API.OpenAITemperature
  source: SourceType
  starterPrompts: StarterPrompt[]
  template: TemplateType
  userInstructions: string
}

export type ConversationsRecordType = Record<string, Record<string, ConversationType>>

type MessagesContextType = {
  addConversation: (botName: string, saveConversation: boolean, localKBotSelection?: KBotConfig | null) => void
  clearMessagesForBot: (botName: string) => void
  addMessage: (
    botName: string,
    newMessage: ChatMessage,
    saveConversation: boolean,
    localKBotSelection?: KBotConfig | null,
    isRespondingTo?: boolean,
    temperature?: API.OpenAITemperature
  ) => Promise<{
    conversationIDUsed: string | undefined
    kBotTemplateId: string | undefined
  }>
  conversations: ConversationsRecordType
  deleteAllMessagesInConversation: (botName: string) => Promise<void>
  deleteConversations: (
    botName: string,
    conversationIDsToDelete: string[],
    keepLocalInstance?: boolean
  ) => Promise<void>
  findEmptyConversationID: (botConversations: Record<string, ConversationType>) => string | undefined
  getAllConversationsForDrawer: (botName: string) => Array<ConversationType & { conversationID: string }>
  getCharLimitForBot: (botName: string) => number
  getConversation: (botName: string, conversationID?: string) => ConversationType | undefined
  getCurrentConversationIDForBot: (botName: string) => string | undefined
  getMessages: (botName: string) => ChatMessage[]
  getNumberOfBotConversations: (botName: string) => number | undefined
  getNumberOfConversations: () => number
  getSummary: (botName: string) => string | null
  isClearingMessages: boolean
  isCreatingConversation: Record<string, boolean>
  isCreatingConversationError: Record<string, string | null>
  isFetchingConversationData: Record<string, boolean>
  isFetchingConversationDataError: Record<string, boolean>
  MAX_CONVERSATIONS: number
  resetHaveMessagesBeenFetchedForBot: (botName: string) => void
  renameConversation: (
    botName: string,
    conversationID: string,
    newConversationName: string,
    saveConversations: boolean
  ) => Promise<void>
  setActiveConversationIDForBot: (botName: string, conversationID: string | null) => void
  setClearingMessages: Dispatch<SetStateAction<boolean>>
  setConversations: Dispatch<SetStateAction<ConversationsRecordType>>
  setConversationSummary: (botName: string, summary: string | null, chatSummaryMemory: number) => void
  setFetchedConversations: (fetchedConversations: ConversationsRecordType, botName: string) => void
  setFetchedMessagesForConversation: (
    botName: string,
    conversationID: string,
    messages: ChatMessage[],
    summary: string[],
    conversationKBotConfig?: ConversationKBotConfig
  ) => void
  setIsFetchingConversationData: Dispatch<SetStateAction<Record<string, boolean>>>
  setIsFetchingConversationDataError: Dispatch<SetStateAction<Record<string, boolean>>>
  setStreamingMessage: Dispatch<SetStateAction<Record<string, ChatMessage | null>>>
  setStreamingSummary: Dispatch<SetStateAction<Record<string, string | null>>>
  streamingMessage: Record<string, ChatMessage | null>
  streamingSummary: Record<string, string | null>
  tooManyConversations: boolean
  updateCharLimitForBot: (botName: string, charLimit: number) => void
  updateConversationIsSaved: (botName: string, conversationID: string, newSettings: ConversationSettings) => void
}

const MAX_CONVERSATIONS = 15

export const MessagesContext = createContext<MessagesContextType>({} as MessagesContextType)

type MessagesProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

export const MessagesProvider = ({ children, config }: MessagesProviderProps) => {
  const { languageAbbreviation } = useI18Context()
  const { API_ENDPOINT } = useConfigContext()
  const { getToken } = useAuthContext()
  const {
    getSelectedFilterValues,
    updateSelectedFilterAndFormValues,
    updateSelectedFilterValues,
    updateSelectedFormValues,
  } = useFiltersContext()
  const { selectedClient } = useAssessmentContext()
  const { updateConversationSettings } = useConversationSettingsContext()

  const [conversations, setConversations] = useState<ConversationsRecordType>({})
  const [streamingMessage, setStreamingMessage] = useState<Record<string, ChatMessage | null>>({})
  const [streamingSummary, setStreamingSummary] = useState<Record<string, string | null>>({})

  const [activeConversationID, setActiveConversationID] = useState<Record<string, string>>({})
  const [isCreatingConversation, setIsCreatingConversation] = useState<Record<string, boolean>>({})
  const [isCreatingConversationError, setIsCreatingConversationError] = useState<Record<string, string | null>>({})
  const [isFetchingConversationData, setIsFetchingConversationData] = useState<Record<string, boolean>>({})
  const [isFetchingConversationDataError, setIsFetchingConversationDataError] = useState<Record<string, boolean>>({})
  const [isClearingMessages, setClearingMessages] = useState(false)

  const [charLimit, setCharLimit] = useState<Record<string, number>>({})

  useEffect(() => {
    config?.forEach((botConfig) => {
      if (botConfig.maxInput) {
        setCharLimit((prev) => ({
          ...prev,
          [botConfig.botName]: botConfig.maxInput,
        }))
      }
    })
  }, [config])

  const getCharLimitForBot = (botName: string): number => {
    return charLimit[botName]
  }

  const updateCharLimitForBot = (botName: string, charLimit: number): void => {
    setCharLimit((prev) => {
      return { ...prev, [botName]: charLimit }
    })
  }

  /**
   * Returns the ID of an empty conversation instance
   * @param {Record<number, ConversationType>} botConversations - The conversations associated with the bot.
   * @returns {number | undefined} The ID of the empty conversation instance, if found; otherwise, undefined.
   */
  const findEmptyConversationID = useCallback(
    (botConversations: Record<string, ConversationType>): string | undefined => {
      const emptyConversationEntry = Object.entries(botConversations).find(
        ([, conversation]) => conversation.messages.length === 0 && conversation.displayName === null
      )

      return emptyConversationEntry ? emptyConversationEntry[0] : undefined
    },
    []
  )

  /**
   * Returns the ID of the conversation opened on a specific bot
   * @param {string} botName - The name of the bot to retrieve the current conversation ID for.
   * @returns {number | undefined} The ID of the conversation opened on the specified bot, or undefined if not found.
   */
  const getCurrentConversationIDForBot = useCallback(
    (botName: string): string | undefined => {
      if (botName in activeConversationID) {
        return activeConversationID[botName]
      }
    },
    [activeConversationID]
  )

  /**
   * Updates the ID of the conversation that is currently opened on a bot
   * @param {string} botName - The name of the bot to update the current conversation ID for.
   * @param {number} conversationID - The ID of the conversation to set as the current conversation.
   * @returns {void}
   */
  const setActiveConversationIDForBot = useCallback((botName: string, conversationID: string | null): void => {
    if (typeof conversationID === 'string') {
      setActiveConversationID((currentActiveConversationID) => {
        return { ...currentActiveConversationID, [botName]: conversationID }
      })
    } else {
      setActiveConversationID((currentActiveConversationID) => {
        const currentConversationCopy = { ...currentActiveConversationID }
        delete currentConversationCopy[botName]
        return currentConversationCopy
      })
    }
  }, [])

  const updateNewConversation = useCallback(
    (
      botName: string,
      created: number,
      newConversationID: string,
      newMessage: ChatMessage,
      saveConversation: boolean,
      isRespondingTo?: boolean,
      kBotInformation?: KBotConfig | null,
      temperature?: API.OpenAITemperature
    ): void => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [newConversationID]: {
              displayName: newMessage.content,
              // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
              messages: isRespondingTo ? (newMessage.role === 'user' ? [] : [newMessage]) : [newMessage],
              summary: null,
              created,
              isSaved: saveConversation,
              haveMessagesBeenFetched: true,
              kbotTemperature: temperature,
              ...(kBotInformation
                ? {
                    description: kBotInformation.description,
                    fileContent: kBotInformation.fileContent,
                    source: kBotInformation.source,
                    starterPrompts: kBotInformation.starterPrompts,
                    template: kBotInformation.template,
                    templateId: kBotInformation.templateId,
                    userInstructions: kBotInformation.userInstructions,
                  }
                : {}),
            },
          },
        }
      })

      updateSelectedFilterAndFormValues(
        botName,
        newConversationID,
        { filters: getSelectedFilterValues(botName, 'temp')?.filters },
        { userQuery: '', clicked: null }
      )

      updateConversationSettings(botName, newConversationID, { saveConversation })

      setActiveConversationIDForBot(botName, newConversationID)
    },
    [
      getSelectedFilterValues,
      setActiveConversationIDForBot,
      updateConversationSettings,
      updateSelectedFilterAndFormValues,
    ]
  )

  type SaveAndUpdateConversationToBEProps = {
    botName: string
    newMessage: ChatMessage
    saveConversation: boolean
    kBotInformation?: KBotConfig | null
    isRespondingTo?: boolean
    temperature?: API.OpenAITemperature
  } & (
    | {
        currentConversation: ConversationType
        currentConversationID: string
      }
    | {
        currentConversation?: never
        currentConversationID?: never
      }
  )

  const saveAndUpdateConversationToBE = useCallback(
    async (props: SaveAndUpdateConversationToBEProps): Promise<string | undefined> => {
      const {
        botName,
        currentConversation,
        currentConversationID,
        isRespondingTo,
        kBotInformation,
        newMessage,
        saveConversation,
        temperature,
      } = props
      try {
        setIsCreatingConversation((currentIsCreatingConversation) => ({
          ...currentIsCreatingConversation,
          [botName]: true,
        }))
        const messageContent = currentConversation?.messages?.[0]?.content ?? newMessage.content
        const displayName = currentConversation?.displayName
        const authToken = await getToken()
        const createConvApiResponse = await fetchData<ConversationCreateResponse>({
          url: `${API_ENDPOINT}/chatapi/CreateConvApi`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            botName,
            convName: displayName ?? (messageContent.length > 100 ? messageContent.slice(0, 100) : messageContent),
            engagementId: botName === 'ASSESSMENT' && selectedClient ? selectedClient.engagementId : undefined,
            language: 'EN',
            temperature,
            templateId: kBotInformation?.templateId,
          },
          rId: `${Math.random()}-${new Date().getTime()}`,
        })

        if ('errorList' in createConvApiResponse) {
          setIsCreatingConversationError((currentIsCreatingConversationError) => ({
            ...currentIsCreatingConversationError,
            [botName]: createConvApiResponse.errorList?.[0]?.code,
          }))
          console.error('Error occurred:', createConvApiResponse.errorList[0].code)
        } else {
          // Set our config values here to move them from temp and assign to the correct conversation ID
          try {
            const authToken = await getToken()
            const updateConvApiResponse = await fetchData<ConversationConfigUpdateResponse>({
              url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
              token: authToken,
              setRetrying: () => false,
              payload: {
                botName,
                convID: createConvApiResponse.conversationId,
                config: {
                  fileContext: newMessage.docContext,
                  kbotTemperature: temperature,
                },
                language: 'EN',
              },
              rId: `${Math.random()}-${new Date().getTime()}`,
            })

            if ('errorList' in updateConvApiResponse) {
              console.error('Error occurred:', updateConvApiResponse.errorList[0].code)
            }
          } catch (error) {
            console.error('Error occurred when trying to update conversation settings', error)
          }
          if (currentConversation && currentConversationID) {
            setConversations((prevConversations) => {
              // Get the botName from the conversations (e.g., GENERAL)
              const sectionConversations = prevConversations[botName]

              // If the botName or conversation doesn't exist, return the previous state
              if (!sectionConversations || !sectionConversations[currentConversationID]) {
                return prevConversations
              }

              // Get the conversation we want to replace
              const conversationToReplace = sectionConversations[currentConversationID]

              // Create a new object with the old key replaced by the new key
              const updatedSection: Record<string, ConversationType> = {
                ...sectionConversations,
                [createConvApiResponse.conversationId]: {
                  ...conversationToReplace,
                  messages: [
                    ...conversationToReplace.messages,
                    // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
                    ...(isRespondingTo ? (newMessage.role === 'user' ? [] : [newMessage]) : [newMessage]),
                  ],
                  isSaved: true,
                  ...(temperature ? { kbotTemperature: temperature } : {}),
                  ...(kBotInformation
                    ? {
                        description: kBotInformation.description,
                        fileContent: kBotInformation.fileContent,
                        source: kBotInformation.source,
                        starterPrompts: kBotInformation.starterPrompts,
                        template: kBotInformation.template,
                        templateId: kBotInformation.templateId,
                        userInstructions: kBotInformation.userInstructions,
                      }
                    : {}),
                }, // Add new key with the conversation
              }

              // Delete the old key
              delete updatedSection[currentConversationID]

              // Return updated conversations state with the updated botName
              return {
                ...prevConversations,
                [botName]: updatedSection,
              }
            })
            setActiveConversationIDForBot(botName, createConvApiResponse.conversationId)
          } else {
            updateNewConversation(
              botName,
              createConvApiResponse.created,
              createConvApiResponse.conversationId,
              newMessage,
              saveConversation,
              isRespondingTo,
              kBotInformation,
              temperature
            )
          }

          setIsCreatingConversationError((currentIsCreatingConversationError) => ({
            ...currentIsCreatingConversationError,
            [botName]: null,
          }))
          return createConvApiResponse.conversationId
        }
      } catch (error) {
        setIsCreatingConversationError((currentIsCreatingConversationError) => ({
          ...currentIsCreatingConversationError,
          [botName]: 'error',
        }))
        console.error('Fetch failed:', error)
      } finally {
        setIsCreatingConversation((currentIsCreatingConversation) => ({
          ...currentIsCreatingConversation,
          [botName]: false,
        }))
      }
    },
    [API_ENDPOINT, getToken, selectedClient, setActiveConversationIDForBot, updateNewConversation]
  )

  /**
   * Adds a new empty instance of a conversation
   * @param {string} botName - The name of the bot to which the conversation will be added.
   * @returns {void}
   */
  const addConversation = useCallback(
    (botName: string, saveConversation: boolean, kBotInformation?: KBotConfig | null): void => {
      // ALWAYS add temp into the filters object, never start a conversation without it
      updateSelectedFilterValues(botName, 'temp')
      const conversationID = getCurrentConversationIDForBot(botName)
      const botConversations = conversations[botName]
      let newConversationID = 'temp'

      if (botName in conversations && conversationID) {
        const emptyConversationID = findEmptyConversationID(botConversations)
        if (conversationID !== emptyConversationID) {
          if (emptyConversationID) {
            newConversationID = emptyConversationID
          } else {
            setConversations((currentConversations) => ({
              ...currentConversations,
              [botName]: {
                ...currentConversations[botName],
                [newConversationID]: {
                  displayName: null,
                  messages: [],
                  summary: null,
                  created: Date.now(),
                  isSaved: saveConversation,
                  haveMessagesBeenFetched: true,
                  ...(kBotInformation
                    ? {
                        description: kBotInformation.description,
                        fileContent: kBotInformation.fileContent,
                        kbotTemperature: kBotInformation.kbotTemperature,
                        source: kBotInformation.source,
                        starterPrompts: kBotInformation.starterPrompts,
                        template: kBotInformation.template,
                        templateId: kBotInformation.templateId,
                        userInstructions: kBotInformation.userInstructions,
                      }
                    : {}),
                },
              },
            }))
          }
          // Running the below update functions will create default instances of filter, form, and conversation settings values for the new conversation ID
          updateSelectedFilterAndFormValues(botName, newConversationID)
          setActiveConversationIDForBot(botName, newConversationID)
        }
      }
      updateConversationSettings(botName, newConversationID, { saveConversation })
    },
    [
      conversations,
      findEmptyConversationID,
      getCurrentConversationIDForBot,
      setActiveConversationIDForBot,
      updateConversationSettings,
      updateSelectedFilterAndFormValues,
      updateSelectedFilterValues,
    ]
  )

  const addMessage = useCallback(
    async (
      botName: string,
      newMessage: ChatMessage,
      saveConversation: boolean,
      kBotInformation?: KBotConfig | null,
      isRespondingTo?: boolean,
      temperature?: API.OpenAITemperature
    ) => {
      let conversationIDUsed = getCurrentConversationIDForBot(botName) ?? 'temp'
      if (
        botName in conversations &&
        conversationIDUsed &&
        conversationIDUsed in conversations[botName] &&
        conversationIDUsed !== 'temp'
      ) {
        // If we are now needing to save the conversation that was originally set to unsaved
        if (saveConversation === true && conversations[botName][conversationIDUsed].isSaved === false) {
          const conversationIDCreated = await saveAndUpdateConversationToBE({
            botName,
            newMessage,
            saveConversation,
            kBotInformation,
            isRespondingTo,
            currentConversation: conversations[botName][conversationIDUsed],
            currentConversationID: conversationIDUsed,
            temperature,
          })

          if (conversationIDCreated) {
            conversationIDUsed = conversationIDCreated
          }
        } else {
          setConversations((currentConversations) => {
            return {
              ...currentConversations,
              [botName]: {
                ...currentConversations[botName],
                [conversationIDUsed]: {
                  ...currentConversations[botName][conversationIDUsed],
                  displayName: currentConversations[botName][conversationIDUsed].displayName ?? newMessage.content,
                  messages: [
                    ...currentConversations[botName][conversationIDUsed].messages,
                    // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
                    ...(isRespondingTo ? (newMessage.role === 'user' ? [] : [newMessage]) : [newMessage]),
                  ],
                  isSaved: saveConversation,
                  ...(temperature ? { kbotTemperature: temperature } : {}),
                  ...(kBotInformation
                    ? {
                        description: kBotInformation.description,
                        fileContent: kBotInformation.fileContent,
                        source: kBotInformation.source,
                        starterPrompts: kBotInformation.starterPrompts,
                        template: kBotInformation.template,
                        templateId: kBotInformation.templateId,
                        userInstructions: kBotInformation.userInstructions,
                      }
                    : {}),
                },
              },
            }
          })
        }
      } else {
        if (saveConversation) {
          const conversationIDCreated = await saveAndUpdateConversationToBE({
            botName,
            newMessage,
            saveConversation,
            kBotInformation,
            isRespondingTo,
            temperature,
          })
          if (conversationIDCreated) {
            conversationIDUsed = conversationIDCreated
          }
        } else {
          const newConversationID = uuidv4()
          conversationIDUsed = newConversationID
          updateNewConversation(
            botName,
            Date.now(),
            newConversationID,
            newMessage,
            saveConversation,
            isRespondingTo,
            kBotInformation,
            temperature
          )
        }
        // Wipe 'temp'
        updateSelectedFormValues(botName, 'temp', { clicked: null, userQuery: '' })
      }
      return {
        conversationIDUsed: saveConversation ? conversationIDUsed : undefined,
        kBotTemplateId: kBotInformation?.templateId,
      }
    },
    [
      conversations,
      getCurrentConversationIDForBot,
      saveAndUpdateConversationToBE,
      updateNewConversation,
      updateSelectedFormValues,
    ]
  )

  /**
   * Delete all messages from the current conversation in the database
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {void}
   */
  const deleteAllMessagesInConversation = useCallback(
    async (botName: string): Promise<void> => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        try {
          setClearingMessages(true)
          const authToken = await getToken()
          const response = await fetchData<ClearChatMessagesResponse>({
            url: `${API_ENDPOINT}/chatapi/ClearChatMessages`,
            token: authToken,
            setRetrying: () => false,
            payload: {
              botName,
              conversationId: conversationID,
              language: 'EN',
            },
            rId: `${Math.random()}-${new Date().getTime()}`,
          })
          if ('errorList' in response) {
            console.error('Error occurred:', response.errorList[0].code)
          }
        } catch {
          console.error('SOMETHING WENT WRONG in deleting conversations')
        } finally {
          setClearingMessages(false)
        }
        setConversations((currentConversations) => ({
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: { ...currentConversations[botName][conversationID], messages: [] },
          },
        }))
      }
    },
    [API_ENDPOINT, conversations, getCurrentConversationIDForBot, getToken]
  )

  /**
   * Resets all messages to blank for a bot in the provider
   * @param {string} botName - The name of the bot.
   * @returns {void}
   */
  const clearMessagesForBot = useCallback((botName: string): void => {
    setConversations((prevConversations) => {
      const updatedConversations = { ...prevConversations }

      if (updatedConversations[botName]) {
        Object.keys(updatedConversations[botName]).forEach((conversationId) => {
          updatedConversations[botName][conversationId] = {
            ...updatedConversations[botName][conversationId],
            messages: [],
          }
        })
      }

      return updatedConversations
    })
  }, [])

  /**
   * Resets all haveMessagesBeenFetched state to false for a bot in the provider
   * @param {string} botName - The name of the bot.
   * @returns {void}
   */
  const resetHaveMessagesBeenFetchedForBot = useCallback((botName: string): void => {
    setConversations((prevConversations) => {
      const updatedConversations = { ...prevConversations }

      if (updatedConversations[botName]) {
        Object.keys(updatedConversations[botName]).forEach((conversationId) => {
          updatedConversations[botName][conversationId] = {
            ...updatedConversations[botName][conversationId],
            haveMessagesBeenFetched: false,
          }
        })
      }

      return updatedConversations
    })
  }, [])

  /**
   * Deletes an entire conversation instance and the settings associated with it
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {string} conversationIDToDelete - The ID of the conversation to be deleted.
   * @returns {void}
   */
  const deleteConversations = useCallback(
    async (botName: string, conversationIDsToDelete: string[], keepLocalInstance?: boolean): Promise<void> => {
      try {
        const authToken = await getToken()
        const response = await fetchData<ConversationDeleteResponse>({
          url: `${API_ENDPOINT}/chatapi/DeleteConvApi`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            botName,
            convList: conversationIDsToDelete,
            language: 'EN',
          },
          rId: `${Math.random()}-${new Date().getTime()}`,
        })
        if ('errorList' in response) {
          console.error('Error occurred:', response.errorList[0].code)
        }
      } catch {
        console.error('SOMETHING WENT WRONG in deleting conversations')
      }
      if (!keepLocalInstance) {
        setConversations((currentConversations) => {
          const filteredConversations: ConversationsRecordType = {}

          for (const key in currentConversations) {
            const conversationRecord = currentConversations[key]
            const filteredRecord: Record<string, ConversationType> = {}

            for (const numKey in conversationRecord) {
              if (!conversationIDsToDelete.includes(numKey)) {
                filteredRecord[numKey] = conversationRecord[numKey]
              }
            }

            if (Object.keys(filteredRecord).length > 0) {
              filteredConversations[key] = filteredRecord
            }
          }

          return filteredConversations
        })

        let isDeletingActiveConversation = false

        const filteredDocContents: Record<string, string> = {}
        for (const key in activeConversationID) {
          const recordNumber = activeConversationID[key]
          if (!conversationIDsToDelete.includes(recordNumber)) {
            filteredDocContents[key] = activeConversationID[key]
          }
        }

        if (!filteredDocContents[botName]) {
          isDeletingActiveConversation = true
        }

        // If we are deleting a conversation that used to be the current conversation ID opened, reset this value
        setActiveConversationID(filteredDocContents)

        if (isDeletingActiveConversation) {
          // If we are deleting an active conversation, the system will go back to using "temp" values. So we need to re-set these to make sure we weren't holding old values
          updateSelectedFilterAndFormValues(botName, 'temp')
        }
      }
    },
    [API_ENDPOINT, activeConversationID, getToken, updateSelectedFilterAndFormValues]
  )

  /**
   * Returns all conversations for a specific bot, including the key as a property, in reverse order
   * @param {string} botName - The name of the bot to retrieve conversations for.
   * @returns {Array<ConversationType & { conversationID: string }>} An array containing all conversations for the specified bot, with each conversation object extended to include the conversationID property.
   */
  const getAllConversationsForDrawer = useCallback(
    (botName: string): Array<ConversationType & { conversationID: string }> => {
      if (botName in conversations) {
        const conversationsArray = Object.entries(conversations[botName]).map(([key, value]) => ({
          ...value, // Spread the properties of the original ConversationType object
          conversationID: key, // Add the key as conversationID and ensure it's a number
        }))
        return [...conversationsArray].reverse()
      }
      return []
    },
    [conversations]
  )

  /**
   * Returns the data related to a conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number | undefined} conversationID - The ID of the conversation to retrieve data for. If undefined, use the getCurrentConversationIDForBot method to get the conversation for current ID
   * @returns {ConversationType | undefined} The data related to the specified conversation, or undefined if the conversation does not exist.
   */
  const getConversation = useCallback(
    (botName: string, conversationID?: string): ConversationType | undefined => {
      if (botName in conversations) {
        if (conversationID) {
          if (conversationID in conversations[botName]) {
            return conversations[botName][conversationID]
          }
        } else {
          const currentConversationID = getCurrentConversationIDForBot(botName)
          if (currentConversationID) {
            return conversations[botName][currentConversationID]
          }
        }
      }
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Returns the message data for the current conversation opened on a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {ChatMessage[]} An array containing the message data for the specified conversation.
   */
  const getMessages = useCallback(
    (botName: string): ChatMessage[] => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        return conversations[botName][conversationID].messages
      }
      return []
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Returns the number of conversations across all bots
   * * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {number} The total number of conversations across all bots.
   */
  const getNumberOfBotConversations = useCallback(
    (botName: string): number | undefined => {
      if (botName in conversations) {
        return Object.keys(conversations[botName]).length
      }
      return undefined
    },
    [conversations]
  )

  /**
   * Returns the number of conversations across all bots
   * @returns {number} The total number of conversations across all bots.
   */
  const getNumberOfConversations = useCallback(() => {
    return Object.values(conversations).reduce((acc, userConversations) => {
      return (
        acc +
        (Object.keys(userConversations).includes('temp')
          ? Object.keys(userConversations).length - 1
          : Object.keys(userConversations).length)
      )
    }, 0)
  }, [conversations])

  /**
   * Returns the summary value of a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {string | null} The summary value of the specified bot, or null if not found.
   */
  const getSummary = useCallback(
    (botName: string): string | null => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        return conversations[botName][conversationID]?.summary?.join('\n') || null
      }
      return null
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Updates the displayName property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number} conversationID - The ID of the conversation to update.
   * @param {string} newConversationName - The new display name for the conversation.
   * @returns {void}
   */
  const renameConversation = useCallback(
    async (
      botName: string,
      conversationID: string,
      newConversationName: string,
      saveConversations: boolean
    ): Promise<void> => {
      if (botName in conversations && conversationID in conversations[botName] && newConversationName.trim().length) {
        setConversations((currentConversations) => {
          return {
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [conversationID]: { ...currentConversations[botName][conversationID], displayName: newConversationName },
            },
          }
        })
        if (saveConversations) {
          try {
            const authToken = await getToken()
            const response = await fetchData<ConversationConfigUpdateResponse>({
              url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
              token: authToken,
              setRetrying: () => false,
              payload: {
                botName,
                convID: conversationID,
                config: {
                  convName: newConversationName,
                },
                language: 'EN',
              },
              rId: `${Math.random()}-${new Date().getTime()}`,
            })

            if ('errorList' in response) {
              console.error('Error occurred:', response.errorList[0].code)
            }
          } catch {
            console.error('Error occurred when trying to save conversation settings')
          }
        }
      }
    },
    [API_ENDPOINT, conversations, getToken]
  )

  /**
   * Updates the summary property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {string | null} summary - The new summary value for the conversation.
   * @returns {void}
   */
  const setConversationSummary = useCallback(
    (botName: string, summary: string | null, chatSummaryMemory: number): void => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        setConversations((currentConversations) => {
          const currentSummary = currentConversations[botName][conversationID]?.summary
          if (summary === null) {
            // Update summary to null and return updated state
            return {
              ...currentConversations,
              [botName]: {
                ...currentConversations[botName],
                [conversationID]: { ...currentConversations[botName][conversationID], summary: null },
              },
            }
          }

          let summaryToSet: Array<string>
          if (Array.isArray(currentSummary)) {
            summaryToSet =
              chatSummaryMemory && currentSummary.length >= chatSummaryMemory
                ? // The current chatSummary contains more than 10 items, so remove the first element and add the new responseSummary
                  [...currentSummary.slice(1), summary]
                : // There is no limitation on the number of summaries we can keep ahold of, so add the new responseSummary
                  [...currentSummary, summary]
          } else {
            // There aren't any summaries yet set for this particular bot, so set the summary with just the new responseSummary
            summaryToSet = [summary]
          }
          return {
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [conversationID]: { ...currentConversations[botName][conversationID], summary: summaryToSet },
            },
          }
        })
      }
    },
    [conversations, getCurrentConversationIDForBot]
  )

  const setFetchedConversations = useCallback(
    (fetchedConversations: ConversationsRecordType, botName: string): void => {
      setConversations((currentConversations) => {
        if (Object.keys(currentConversations).length === 0 && currentConversations.constructor === Object) {
          // If it's the first time we are setting conversations, just set the useState with what the API returned
          return fetchedConversations
        } else {
          // Spreading current conversations prevents removing unsaved conversations from other bots in the current session.
          // If we have previously fetched conversations for the app, just set those conversations we fetched for the bot we are now on
          return {
            ...currentConversations,
            [botName]: fetchedConversations[botName],
          }
        }
      })
    },
    []
  )

  const setFetchedMessagesForConversation = useCallback(
    (
      botName: string,
      conversationID: string,
      messages: ChatMessage[],
      summary: string[],
      conversationKBotConfig?: ConversationKBotConfig
    ): void => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: {
              ...currentConversations[botName][conversationID],
              haveMessagesBeenFetched: true,
              messages,
              summary,
              ...(conversationKBotConfig
                ? {
                    description: conversationKBotConfig.description,
                    fileContent: conversationKBotConfig.fileContent,
                    kbotTemperature: conversationKBotConfig.kbotTemperature,
                    source: conversationKBotConfig.source,
                    starterPrompts: conversationKBotConfig.starterPrompts,
                    template: conversationKBotConfig?.template[languageAbbreviation]?.length
                      ? conversationKBotConfig.template
                      : currentConversations[botName][conversationID].template,
                    userInstructions: conversationKBotConfig.userInstructions,
                  }
                : {}),
            },
          },
        }
      })
    },
    [languageAbbreviation]
  )

  const updateConversationIsSaved = useCallback(
    (botName: string, conversationID: string, newSettings: ConversationSettings) => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: {
              ...currentConversations[botName][conversationID],
              isSaved: newSettings.saveConversation,
            },
          },
        }
      })
    },
    []
  )

  const tooManyConversations = useMemo(
    () => getNumberOfConversations() >= MAX_CONVERSATIONS,
    [getNumberOfConversations]
  )

  return (
    <>
      <MessagesContext.Provider
        value={{
          addConversation,
          addMessage,
          clearMessagesForBot,
          conversations,
          deleteAllMessagesInConversation,
          deleteConversations,
          findEmptyConversationID,
          getAllConversationsForDrawer,
          getCharLimitForBot,
          getConversation,
          getCurrentConversationIDForBot,
          getMessages,
          getNumberOfBotConversations,
          getNumberOfConversations,
          getSummary,
          isClearingMessages,
          isCreatingConversation,
          isCreatingConversationError,
          isFetchingConversationData,
          isFetchingConversationDataError,
          MAX_CONVERSATIONS,
          renameConversation,
          resetHaveMessagesBeenFetchedForBot,
          setActiveConversationIDForBot,
          setClearingMessages,
          setConversations,
          setConversationSummary,
          setFetchedConversations,
          setFetchedMessagesForConversation,
          setIsFetchingConversationData,
          setIsFetchingConversationDataError,
          setStreamingMessage,
          setStreamingSummary,
          streamingMessage,
          streamingSummary,
          tooManyConversations,
          updateCharLimitForBot,
          updateConversationIsSaved,
        }}
      >
        {children}
      </MessagesContext.Provider>
    </>
  )
}

export const useMessagesContext = (): MessagesContextType => useContext(MessagesContext)
