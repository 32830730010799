import { useEffect } from 'react'
import { IconType } from 'react-icons'
import { Image as ChakraImage } from '@chakra-ui/image'
import { SkeletonCircle } from '@chakra-ui/skeleton'

import { useLRUCache } from 'hooks/useLRUCache'

import { useBotContext } from 'providers/BotProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

type BotIconWithFallbackProps = {
  alt: string
  botName: string
  className: string
  fallbackContent: string | IconType
}

const BotIconWithFallback: React.FC<BotIconWithFallbackProps> = ({ botName, alt, fallbackContent, className }) => {
  const { isLightMode } = useSettingsContext()
  const { getBotImageStatus, setBotImageStatus } = useBotContext()

  const assetsPrefix = `${botName.toLocaleLowerCase()}/assets/`
  const lightModeSrc = `${assetsPrefix}botIconBlack.png`
  const darkModeSrc = `${assetsPrefix}botIconWhite.png`

  const src = isLightMode ? lightModeSrc : darkModeSrc
  const cacheKey = `${botName.toLocaleLowerCase()}`

  // stores the state of the images ('loading', 'fallback', 'object URL')
  const imageSrc = getBotImageStatus(cacheKey)

  const [getCachedValue, setCachedValue] = useLRUCache<string | Blob, string>()

  // Load and cache image
  useEffect(() => {
    const loadAndCacheImage = async () => {
      const cachedImage = await getCachedValue([cacheKey])

      if (cachedImage) {
        if (typeof cachedImage === 'string') {
          setBotImageStatus(cacheKey, cachedImage)
        } else {
          // convert Blob to Object URL
          const objectUrl = URL.createObjectURL(cachedImage)
          setBotImageStatus(cacheKey, objectUrl)
        }
        return
      }

      const img = new Image()
      img.src = src

      img.onload = async () => {
        try {
          // convert loaded image to Blob
          const response = await fetch(src)
          const blob = await response.blob()

          // Cache image as a Blob
          await setCachedValue([cacheKey], blob)

          // Convert Blob to Object URL
          const objectUrl = URL.createObjectURL(blob)
          setBotImageStatus(cacheKey, objectUrl)
        } catch (error) {
          setBotImageStatus(cacheKey, 'fallback')
        }
      }

      img.onerror = () => {
        setBotImageStatus(cacheKey, 'fallback')
      }
    }

    loadAndCacheImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, fallbackContent, cacheKey])

  if (imageSrc === 'loading') {
    return <SkeletonCircle className={className} />
  }

  // 'fallback' means fallback
  if (imageSrc === 'fallback') {
    if (typeof fallbackContent === 'string') {
      return <ChakraImage alt="Robot" src={fallbackContent} className={className} />
    } else {
      const FallbackIcon = fallbackContent as IconType
      return <FallbackIcon className={className} />
    }
  }

  return <ChakraImage alt={alt} src={src} className={className} />
}

export { BotIconWithFallback }
