import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Text } from '@chakra-ui/layout'
import { SourceType } from '@kleo/types'

import { ModalBox } from 'components/Modal'
import { UploadModal } from 'components/UploadModal'

import { trimFormValues } from 'utils/KBotsUtils'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useUploadContext } from 'providers/UploadProvider'

import type { KBotFormState } from 'types/types'

import { CreateEditForm } from './CreateEditForm'

export const CreateEdit = ({
  botName,
  maxFileNameLength,
  jsonMaxFileSize,
  state,
  uploadFileMaxCount,
  uploadTokenMaxSize,
}: {
  botName: string
  maxFileNameLength: number
  jsonMaxFileSize: string
  state: KBotFormState
  uploadFileMaxCount: number
  uploadTokenMaxSize: number
}) => {
  const conversationId = state === 'edit' ? 'editKBotTemp' : 'createKBotTemp'
  const { t } = useI18Context()
  const {
    clearFormState,
    createValidate,
    editValidate,
    getIndividualKBotData,
    getKBotFormValue,
    getKBotFormValues,
    getTranslatedValue,
    isJSONUploading,
    isJSONUploadOpen,
    localKBotSelectionsDetails,
    onJSONUpload,
    onJSONUploadClose,
    saveKBot,
    setKBotFormValue,
    setLocalKBotSelectionsDetails,
    setShouldFetchKBots,
    setUploadJSONError,
    uploadJSONError,
  } = useKBotContext()
  const { deleteDocumentContent, docContents, uploadFileRequest } = useUploadContext()

  const {
    isOpen: isBotCreationFinishedOpen,
    onOpen: onOpenBotCreationFinished,
    onClose: onCloseBotCreationFinished,
  } = useDisclosure()

  const [submissionErrors, setSubmissionErrors] = useState<Record<KBotFormState, string[]>>({ create: [], edit: [] })

  const updateSubmissionError = (formState: KBotFormState, error: string[]) => {
    setSubmissionErrors((prevState) => ({
      ...prevState,
      [formState]: error,
    }))
  }

  const nameValues = useMemo(() => getKBotFormValue('name', state), [getKBotFormValue, state])

  const handleSubmit = async () => {
    try {
      updateSubmissionError(state, [])
      const currentFormValues = getKBotFormValues(state)
      const cleanedFormValues = trimFormValues(currentFormValues)

      await (state === 'edit' ? editValidate(cleanedFormValues) : createValidate(cleanedFormValues))

      const saveResponse = await saveKBot({
        state,
        payload: { ...cleanedFormValues },
      })

      if (saveResponse && 'templateId' in saveResponse && 'template' in saveResponse) {
        setShouldFetchKBots(true)
        if (cleanedFormValues.fixedName && cleanedFormValues.fixedName === localKBotSelectionsDetails?.name) {
          // If for this edited K-Bot we have it selected in the New Conversation modal, we need to re-fetch it's details based on latest edit changes
          const kBotData = await getIndividualKBotData(SourceType.user, cleanedFormValues.fixedName)
          if (kBotData) {
            if (kBotData.name === localKBotSelectionsDetails?.name) {
              setLocalKBotSelectionsDetails(kBotData)
            }
          }
        }
        // set the id on the current kbot state
        setKBotFormValue('templateId', saveResponse.templateId, state)
        setKBotFormValue('name', saveResponse.template, state)
        onOpenBotCreationFinished()
      }

      if ('errorList' in saveResponse) {
        const codes = saveResponse.errorList.map((error) => t(`kBots.error.${error.code}`))
        updateSubmissionError(state, codes)
      }
    } catch (e) {
      updateSubmissionError(state, [t('kBots.createEdit.unableToCreateKBot')])
      // do something when it fails
    }
  }

  // File upload
  const handleFileSubmit = useCallback(
    (files: File[]) => {
      if (files?.length) {
        uploadFileRequest(botName, files[0], false, uploadTokenMaxSize, 0, conversationId, true)
      }
    },
    [botName, conversationId, uploadFileRequest, uploadTokenMaxSize]
  )

  // sync the uploadprovider state with the formState
  useEffect(() => {
    if (botName in docContents && conversationId in docContents[botName]) {
      setKBotFormValue('fileContent', docContents[botName][conversationId].text, state)
    }
  }, [botName, conversationId, docContents, setKBotFormValue, state])

  const handleModalClose = useCallback(
    (resetForm = true) => {
      onCloseBotCreationFinished()
      if (resetForm) {
        clearFormState(state)
        deleteDocumentContent(botName, [conversationId])
      }
    },
    [botName, clearFormState, conversationId, deleteDocumentContent, onCloseBotCreationFinished, state]
  )

  const handleJSONUpload = (files: File[]) => {
    onJSONUpload(files, state, uploadTokenMaxSize)
  }

  return (
    <>
      <ModalBox
        isOpen={isBotCreationFinishedOpen}
        modalHeader={t(`kBots.createEdit.${state}BotSuccess`)}
        modalBody={
          <Text className="text-sm">
            {t(`kBots.createEdit.${state}Success`, { name: getTranslatedValue(nameValues) })}
          </Text>
        }
        onClose={handleModalClose}
      />
      <UploadModal
        botName={botName}
        isFileUploading={isJSONUploading}
        isOpen={isJSONUploadOpen}
        maxFileNameLength={maxFileNameLength}
        maxFileSize={jsonMaxFileSize}
        maxNumFiles={uploadFileMaxCount}
        onClose={onJSONUploadClose}
        onError={(error: string) => setUploadJSONError(error)}
        onSubmit={handleJSONUpload}
        supportedFileExtensions={[]}
        supportedMimeTypes={['application/json']}
        uploadError={uploadJSONError}
        uploadTokenMaxSize={0}
      />
      <CreateEditForm
        botName={botName}
        conversationId={conversationId}
        fileSubmit={handleFileSubmit}
        state={state}
        submissionErrors={submissionErrors}
        submit={handleSubmit}
      />
    </>
  )
}
