import { DocChatAPIRequest } from './api.docChat.types.js'
import { Document, TRequest, TResponse } from './api.types.js'

export type Question = {
  uuid: string
  question: string
}

export type ReportBlobMetaData = {
  reportId: string
  created: string
}

export type ReportHierarchy = {
  [tier1: string]: {
    [tier2: string]: {
      [categoryId: string]: ReportBlobMetaData[]
    }
  }
}

export type StatusErrorEvent = {
  step: string
  errorCode: string
  requestId: string
}

export type ClientEngagementConfig = {
  engagementId: string
  label: {
    en: string
    fr: string
  }
  businessAssociation: string
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientEngagementConfigAPIRequest extends TRequest {}

export interface ClientEngagementConfigAPIResponse extends TResponse {
  clientEngagements: ClientEngagementConfig[]
}

export interface RunReportAPIRequest extends TRequest {
  engagementId: string
  configurationId: string
  docList: Document[]
  uiRequestId: string
}

export interface RunReportAPIResponse extends TResponse {
  reportId: string
}

export interface RunReportAPIErrorResponse extends TResponse {
  error: RunReportAPIError
  errorData?: ErrorData
}

export interface ASGetUrlUploadReq extends TRequest {
  engagementId: string
  filename: string
  contentType: string
}

export interface GetUrlUploadResponse extends TResponse {
  configurationId: string
  url: string
}

export interface GetEngagementDataAPIRequest extends TRequest {
  engagementId: string
}

export interface GetReportStatusAPIRequest extends TRequest {
  engagementId: string
  reportId: string
}

export interface GetReportStatusAPIResponse extends TResponse {
  data: { [key: string]: boolean }
  error?: StatusErrorEvent
  lastUpdated?: string
}

export interface GetReportsHierarchyAPIRequest extends TRequest {
  engagementId: string
}

export interface GetReportsHierarchyAPIResponse extends TResponse {
  hierachy: ReportHierarchy
}
export interface GetReportDataAPIRequest extends TRequest {
  engagementId: string
  reportId: string
  fileLocation: 'adhoc' | 'standard'
}
export interface GetReportDataAPIResponse extends TResponse {
  data: { [key: string]: string | object }
}

export interface ExportReportDataAPIRequest extends TRequest {
  engagementId: string
  tierIds: string[]
  fileLocation: 'adhoc' | 'standard'
  latest?: boolean
}
export interface SaveUserReportAPIRequest extends TRequest {
  engagementId: string
  reportId: string
}

export interface SaveUserReportAPIResponse extends TResponse {
  reportDestinationPath: string
}

export enum RunReportAPIError {
  // configFailed is defined here so that the FE can use the RunReportAPIError type and use this value as a fallback
  configFailed = 'configFailed',
  invalidPrompts = 'invalidPrompts',
  tooManyReports = 'tooManyReports',
  invalidReport = 'invalidReport',
  maxFileSize = 'maxFileSize',
  promptMissingField = 'promptMissingField',
  emptyPrompt = 'emptyPrompt',
  unrecognizedPrompt = 'unrecognizedPrompt',
  promptFirstCellUndefined = 'promptFirstCellUndefined',
  missingPrompt = 'missingPrompt',
  unsupportedCellFormat = 'unsupportedCellFormat',
  emptyReport = 'emptyReport',
  datasetMissingField = 'datasetMissingField',
  emptyDocList = 'emptyDocList',
  invalidFile = 'invalidFile',
  tooManyQuestions = 'tooManyQuestions',
  promptTooLarge = 'promptTooLarge',
}

export type ErrorData = {
  field?: string
  row?: number
  column?: number
  prompt?: string
}
export interface ASDocChatAPIRequest extends DocChatAPIRequest {
  conversationId?: string
  engagementId: string
  isRespondingTo?: boolean
}
export interface ASDocChatDocumentAPIRequest extends TRequest {
  engagementId: string
}
