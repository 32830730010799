import { BiInfoCircle } from 'react-icons/bi'
import { FormLabel } from '@chakra-ui/form-control'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { Textarea } from '@chakra-ui/textarea'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotInstructions = ({
  formErrors,
  instructionsValue,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  instructionsValue: string
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" id="kbot-instructions-heading" size="sm" className="mr-2">
          {t('kBots.createEdit.instructions')}{' '}
          <Text as="span" className="text-red-600">
            *
          </Text>
        </Heading>
        <Tooltip
          className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
          label={
            <>
              <Text>{t('kBots.createEdit.instructionsExplanation')}</Text>
              <Text>{t('kBots.createEdit.instructionsExplanation2')}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-instructions-heading"
        >
          <Fade in>
            <BiInfoCircle className="self-end w-4 h-4 text-gray-600" />
          </Fade>
        </Tooltip>
      </Box>
      {formErrors.instructions && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.instructions)}
        </Text>
      )}
      <VisuallyHidden>
        <FormLabel htmlFor="instructions-textarea">{t('kBots.createEdit.instructions')}</FormLabel>
      </VisuallyHidden>
      <Textarea
        id="instructions-textarea"
        className="mb-3 border rounded-md border-kpmgGray3 focus-visible:border-kpmgGray3 focus-visible:shadow-none"
        value={instructionsValue}
        onChange={(e) => setKBotFormValue('instructions', e.target.value, state)}
        placeholder={t('kBots.createEdit.typeSomething')}
        size="sm"
        maxLength={8000}
        isDisabled={shouldInputsBeDisabled}
      />
    </>
  )
}
